import React from 'react';
import './index.scss';
import { Typography } from '../Typography';

interface Props {
  showSpinner: boolean;
  loadingMessage?: any;
  type?: 'btn_spinner';
}

export const Spinner = (props: Props) => {
  const { showSpinner, loadingMessage, type } = props;
  return (
    <div className={`center ${showSpinner ? '' : 'spinner_disabled'}`}>
      <div className="spinner-container">
        <div className={`${type ? type : 'loading-spinner'}`}></div>
      </div>
      <Typography type="heading4" text={loadingMessage} />
    </div>
  );
};

export const SpinnerPro = () => {
  return (
    <div className="spinner-wrapper">
      <span className="loader" />
      <Typography type="base_regular" text="Please wait" />
    </div>
  );
};
