import React from 'react';
import { AdminHeader, AdminSidebar } from 'components';
import './index.scss';

export const EmployeeSummaryDashboard = () => {
  return (
    <>
      <AdminHeader />
      <div className="dashboard-wrapper">
        <AdminSidebar />
        <div className="employee-summery-dashboard-wrapper">
          <iframe
            title="LMS - Employee Utilization"
            width="100%"
            height="100%"
            src="https://app.powerbi.com/reportEmbed?reportId=60d8e716-f3ac-463a-8929-5995a70c07b9&autoAuth=true&ctid=7a4d6399-cacc-4f87-94ed-181bf634e133"
            allowFullScreen
          />
        </div>
      </div>
    </>
  );
};
