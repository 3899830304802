import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { getToken } from 'store/Auth';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { useNavigate } from 'react-router';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from 'config';
import { AuthRequest } from 'models/models';
import { Spinner } from 'components/atoms';

export const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(
    (state: RootState) => state.auth.user.isAuthenticated
  );
  const userRole = useAppSelector(
    (state: RootState) => state.auth.user.authData?.data?.userRole
  );

  const aadLogin = async () => {
    await instance.loginRedirect(loginRequest).catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      const request = {
        ...loginRequest,
        account: accounts[0]
      };

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          if (response.accessToken) {
            const query: AuthRequest = {
              token: response.accessToken
            };
            dispatch(getToken(query));
          }
        })
        .catch((error) => {
          console.error(error);
          aadLogin();
        });
    }
  }, [isAuthenticated, accounts, dispatch, instance]);

  useEffect(() => {
    if (isAuth) {
      setIsLoading(false);

      navigate('/');
    }
  }, [isAuth, navigate]);

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      aadLogin();
    }
  }, [isAuthenticated, inProgress, aadLogin]);

  return (
    <>
      {!isAuthenticated && inProgress !== InteractionStatus.None && (
        <Spinner showSpinner={true} loadingMessage={'Awaiting User Action..'} />
      )}
      {isLoading && (
        <Spinner
          showSpinner={true}
          loadingMessage={'Signing in.. Please wait..'}
        />
      )}
    </>
  );
};
