import React from 'react';
import './_headerSection.scss';

interface Prop {
  child1: React.ReactNode;
  child2?: React.ReactNode;
}

export const HeaderSection = ({ child1, child2 }: Prop) => {
  return (
    <div className="headerSection">
      <div className="child1"> {child1} </div>
      <div className="child2"> {child2} </div>
    </div>
  );
};
