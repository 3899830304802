import React, { useEffect, useState } from 'react';
import {
  Typography,
  AdminHeader,
  AdminSidebar,
  ButtonPro,
  InputPro,
  SpinnerPro
} from 'components';
import { useNavigate } from 'react-router';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { getAllLeaveSummary } from 'store/Admin';
import { ApiResponseStatus } from 'models/enums';
import CloseIcon from '@mui/icons-material/Close';
import './index.scss';

interface LeaveDetails {
  employeeName: string;
  leaveType: string;
  utilized: string;
}

const LeaveDetailsPopup: React.FC<{
  leaveDetails: LeaveDetails;
  onClose: () => void;
}> = ({ leaveDetails, onClose }) => {
  console.log('Rendering popup with details:', leaveDetails);
  return (
    <div className="popup-wrapper">
      <div className="popup-content">
        <div className="popup-header">
          <Typography text={leaveDetails.employeeName} type="base_medium" />
          <CloseIcon className="close-icon" onClick={onClose} />
        </div>
        <Typography text={`Leave Type: ${leaveDetails.leaveType}`} />
        <Typography text={`Utilized: ${leaveDetails.utilized}`} />
      </div>
    </div>
  );
};

export const AdminLeaveSummary: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLeaveDetails, setSelectedLeaveDetails] =
    useState<LeaveDetails | null>(null);

  const allLeaveSummary = useAppSelector((state: RootState) => {
    return state.admin.allLeaveSummary.filter((leaveData) =>
      leaveData.employeeName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const allLeaveSummaryStatus = useAppSelector((state: RootState) => {
    return state.admin.allLeaveSummaryStatus;
  });

  const getLeaveData = (leaveType: string, allocationLeaves: any[]) => {
    const leaveData = allocationLeaves.find(
      (leave) => leave.leaveType === leaveType
    );
    return {
      available: leaveData?.remainingCount || 0,
      utilized: leaveData?.utilizedCount || 0,
      pending: leaveData?.pendingCount || 0
    };
  };

  useEffect(() => {
    dispatch(getAllLeaveSummary());
  }, []);

  const handleUtilizedClick = (leaveDetails: LeaveDetails) => {
    console.log('Clicked on utilized: ', leaveDetails);
    setSelectedLeaveDetails(leaveDetails);
  };

  const handleCloseupPop = () => {
    setSelectedLeaveDetails(null);
  };

  return (
    <>
      <AdminHeader />
      <div className="dashboard-wrapper">
        {allLeaveSummaryStatus === ApiResponseStatus.pending && (
          <div className="spinner-main-wrapper">
            <SpinnerPro />
          </div>
        )}

        <AdminSidebar />
        <div className="manage-employe-container">
          <div className="manage-employe-title leave-summary">
            <Typography
              text="Employee Leave Summary"
              type="base_regular"
              weight="bold"
            />

            <div className="summary-actions">
              <InputPro
                label=""
                name="search"
                placeholder="Search by employee name"
                inputWrapperClass="name-input-wrapper margin-24"
                value={searchTerm}
                onChange={(value) => setSearchTerm(value)}
              />
              <ButtonPro
                label="Dashboard"
                type="primary"
                onClick={() => navigate('/admin/leave-summary-dashboard')}
              />
            </div>
          </div>

          <div className="employee-leave-summary-wrapper">
            <table>
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{ borderBottom: 'none', minWidth: 200 }}
                  />
                  <th
                    colSpan={3}
                    scope="colgroup"
                    style={{
                      backgroundColor: '#cce5ff',
                      borderRight: '1px solid #a8a8a8'
                    }}
                  >
                    Annual
                  </th>
                  <th
                    colSpan={3}
                    scope="colgroup"
                    style={{
                      backgroundColor: '#d4edda',
                      borderRight: '1px solid #a8a8a8'
                    }}
                  >
                    Casual
                  </th>
                  <th
                    colSpan={2}
                    scope="colgroup"
                    style={{
                      backgroundColor: '#f8d7da',
                      borderRight: '1px solid #a8a8a8'
                    }}
                  >
                    Sick
                  </th>
                  <th
                    colSpan={3}
                    scope="colgroup"
                    style={{
                      backgroundColor: '#fff3cd',
                      borderRight: '1px solid #a8a8a8'
                    }}
                  >
                    Lieu-leave
                  </th>
                  <th
                    colSpan={2}
                    scope="colgroup"
                    style={{ backgroundColor: '#e2e3e5' }}
                  >
                    No Pay
                  </th>
                </tr>
                <tr>
                  <th scope="col" style={{ borderTop: 'none' }} />
                  <th scope="col">Available</th>
                  <th scope="col">Utilized</th>
                  <th
                    scope="col"
                    style={{
                      borderRight: '1px solid #a8a8a8'
                    }}
                  >
                    Pending
                  </th>

                  <th scope="col">Available</th>
                  <th scope="col">Utilized</th>
                  <th
                    scope="col"
                    style={{
                      borderRight: '1px solid #a8a8a8'
                    }}
                  >
                    Pending
                  </th>

                  <th scope="col">Utilized</th>
                  <th
                    scope="col"
                    style={{
                      borderRight: '1px solid #a8a8a8'
                    }}
                  >
                    Pending
                  </th>

                  <th scope="col">Available</th>
                  <th scope="col">Utilized</th>
                  <th
                    scope="col"
                    style={{
                      borderRight: '1px solid #a8a8a8'
                    }}
                  >
                    Pending
                  </th>

                  <th scope="col">Utilized</th>
                  <th scope="col">Pending</th>
                </tr>
              </thead>
              <tbody>
                {allLeaveSummary.map((leaveData, index) => {
                  const annualLeave = getLeaveData(
                    'Annual Leave',
                    leaveData.allocationLeaves
                  );
                  const casualLeave = getLeaveData(
                    'Casual Leave',
                    leaveData.allocationLeaves
                  );
                  const sickLeave = getLeaveData(
                    'Medical Leave',
                    leaveData.allocationLeaves
                  );
                  const lieuLeave = getLeaveData(
                    'Lieu Leave',
                    leaveData.allocationLeaves
                  );
                  const noPayLeave = { utilized: 0, pending: 0 };

                  return (
                    <tr
                      key={`${leaveData.employeeName}-${index}`}
                      style={{
                        borderBottom: '1px solid #dee2e6',
                        padding: '8px 0'
                      }}
                    >
                      <td>{leaveData.employeeName}</td>
                      <td>{annualLeave.available}</td>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleUtilizedClick({
                            employeeName: leaveData.employeeName,
                            leaveType: 'Annual Leave',
                            utilized: 'Family Matter'
                          })
                        }
                      >
                        {annualLeave.utilized}
                      </td>
                      <td
                        style={{
                          borderRight: '1px solid #a8a8a8'
                        }}
                      >
                        {annualLeave.pending}
                      </td>
                      <td>{casualLeave.available}</td>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleUtilizedClick({
                            employeeName: leaveData.employeeName,
                            leaveType: 'Casual Leave',
                            utilized: casualLeave.utilized
                          })
                        }
                      >
                        {casualLeave.utilized}
                      </td>
                      <td
                        style={{
                          borderRight: '1px solid #a8a8a8'
                        }}
                      >
                        {casualLeave.pending}
                      </td>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleUtilizedClick({
                            employeeName: leaveData.employeeName,
                            leaveType: 'Sick Leave',
                            utilized: casualLeave.utilized
                          })
                        }
                      >
                        {sickLeave.utilized}
                      </td>
                      <td
                        style={{
                          borderRight: '1px solid #a8a8a8'
                        }}
                      >
                        {sickLeave.pending}
                      </td>
                      <td>{lieuLeave.available}</td>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleUtilizedClick({
                            employeeName: leaveData.employeeName,
                            leaveType: 'Lieu Leave',
                            utilized: casualLeave.utilized
                          })
                        }
                      >
                        {lieuLeave.utilized}
                      </td>
                      <td
                        style={{
                          borderRight: '1px solid #a8a8a8'
                        }}
                      >
                        {lieuLeave.pending}
                      </td>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleUtilizedClick({
                            employeeName: leaveData.employeeName,
                            leaveType: 'No Pay Leave',
                            utilized: casualLeave.utilized
                          })
                        }
                      >
                        {noPayLeave.utilized}
                      </td>
                      <td>{noPayLeave.pending}</td>
                    </tr>
                  );
                })}

                {allLeaveSummary.length === 0 && (
                  <tr>
                    <td colSpan={15}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedLeaveDetails && (
        <LeaveDetailsPopup
          leaveDetails={selectedLeaveDetails}
          onClose={handleCloseupPop}
        />
      )}
    </>
  );
};
