import React, { useEffect, useState } from 'react';
import {
  ButtonPro,
  DatePickerPro,
  Input,
  Select,
  TextAreaPro,
  Typography
} from 'components';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { AdminEmployeeInfo } from 'models/models';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import {
  ApiResponseStatus,
  UserRoleNames,
  MaritalStatus,
  GenderTypes
} from 'models/enums';
import { createEmployee, updateEmployee } from 'store/Admin';
import './index.scss';
import { error } from 'console';
import { getDesignations } from 'store/MasterData';

const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

const statusOptions = [
  { value: 'Probation', label: 'Probation' },
  { value: 'Confirmed', label: 'Confirmed' },
  { value: 'Internship', label: 'Internship' },
  { value: 'Contract', label: 'Contract' }
];

const userRoles = Object.keys(UserRoleNames).map((key) => ({
  value: key,
  label: UserRoleNames[key as keyof typeof UserRoleNames]
}));

const maritalStatus = Object.keys(MaritalStatus).map((key) => ({
  value: key,
  label: MaritalStatus[key as keyof typeof MaritalStatus]
}));

const genderType = Object.keys(GenderTypes).map((key) => ({
  value: key,
  label: GenderTypes[key as keyof typeof GenderTypes]
}));

const employeeActiveStatus = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' }
];

const terminatedByOptions = [{ value: 'HR', label: 'HR' }];

const initialState: AdminEmployeeInfo = {
  userRoleId: '',
  role: '',
  upn: '',
  userName: '',
  employeeInfoId: '',
  employeeNameWithInitials: '',
  employeeFullName: '',
  employeeNickName: '',
  epfNumber: '',
  mobileNumber: '',
  nic: '',
  passportNumber: '',
  dateOfBirth: null,
  residentialPhoneNumber: '',
  emergencyContactPerson: '',
  emergencyContactPersonPhoneNumber: '',
  emergencyContactPersonRelationship: '',
  personalEmail: '',
  houseNumber: '',
  address: '',
  district: '',
  province: '',
  temporyAddress: '',
  employmentInfoId: '',
  joinedDate: null,
  status: '',
  designationDate: null,
  confirmationDate: null,
  confirmedBy: '',
  terminationDate: null,
  terminatedBy: '',
  terminatedReason: '',
  annualLeaveCount: 0,
  casualLeaveCount: 0,
  medicalLeaveCount: 0,
  liueLeaveCount: 0,
  noPayLeaveCount: 0,
  remarks: '',
  isActive: 'true',
  designation: '',
  employeeExtensions: []
};

const ScrollToError = () => {
  const { isSubmitting, isValid } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValid) {
      const errorElement = document.querySelector(
        '.input-error'
      ) as HTMLElement;
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        errorElement.focus({ preventScroll: true });
      }
    }
  }, [isSubmitting, isValid]);

  return null;
};

export const AddEmployeeForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id: selectedEmployeeId } = useParams();

  const [initialValues, setInitialValues] = useState(initialState);

  const selectedEmployee = useAppSelector((state: RootState) => {
    return selectedEmployeeId
      ? state.admin.employeeData.find(
          (employee) => employee.userRoleId === selectedEmployeeId
        )
      : null;
  });

  const createEmployeeStatus = useAppSelector((state: RootState) => {
    return state.admin.createUserStatus;
  });

  const updateEmployeeStatus = useAppSelector((state: RootState) => {
    return state.admin.updateUserStatus;
  });

  const designations = useAppSelector((state: RootState) => {
    return state.masterData.designations;
  });

  useEffect(() => {
    if (designations.length === 0) dispatch(getDesignations());
  }, []);

  const validationSchema = Yup.object({
    employeeFullName: Yup.string().max(100).required('Required'),
    employeeNameWithInitials: Yup.string().max(50).required('Required'),
    employeeNickName: Yup.string().max(50).required('Required'),
    dateOfBirth: Yup.date().required('Required'),
    gender: Yup.string().required('Required'),
    nic: Yup.string().max(12).required('Required'),
    passportNumber: Yup.string().max(36).nullable(),
    mobileNumber: Yup.string()
      .max(10)
      .required('Required')
      .matches(/^\+?[0-9]+$/, 'Must be only digits'),
    residentialPhoneNumber: Yup.string().max(10).nullable(),
    personalEmail: Yup.string().max(40).nullable(),
    religion: Yup.string().required('Required'),
    nationality: Yup.string().required('Required'),
    maritalStatus: Yup.string().required('Required'),
    upn: Yup.string()
      .max(45)
      .email('Invalid email address')
      .required('Required'),
    houseNumber: Yup.string().max(10).nullable(),
    address: Yup.string().required('Required'),
    district: Yup.string().max(15).nullable(),
    province: Yup.string().max(15).nullable(),
    epfNumber: Yup.string().max(10).required('Required'),
    role: Yup.string().max(50).required('Required'),
    designation: Yup.string().required('Required'),
    joinedDate: Yup.date().required('Required'),
    confirmationDate: Yup.date().required('Required'),
    status: Yup.string().max(15).required('Required'),
    techStack: Yup.string().required('Required'),
    annualLeaveCount: Yup.number()
      .required('Required')
      .typeError('Must be a number'),
    casualLeaveCount: Yup.number()
      .required('Required')
      .typeError('Must be a number'),
    medicalLeaveCount: Yup.number()
      .required('Required')
      .typeError('Must be a number'),
    liueLeaveCount: Yup.number()
      .required('Required')
      .typeError('Must be a number'),
    emergencyContactPerson: Yup.string().max(25).required('Required'),
    emergencyContactPersonPhoneNumber: Yup.string()
      .max(10)
      .required('Required')
      .matches(/^\+?[0-9]+$/, 'Must be only digits'),
    emergencyContactPersonRelationship: Yup.string()
      .max(15)
      .required('Required'),
    isActive: Yup.boolean().required('Required')
  });

  const convertToBoolean = (value: string | boolean) => {
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    }
    return Boolean(value);
  };

  const handleSubmit = (values: AdminEmployeeInfo) => {
    const formattedDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');

    const formattedValues = {
      ...values,
      dateOfBirth: values.dateOfBirth
        ? moment(values.dateOfBirth).format(dateFormat)
        : '',
      joinedDate: values.joinedDate
        ? moment(values.joinedDate).format(dateFormat)
        : '',
      confirmationDate: values.confirmationDate
        ? moment(values.confirmationDate).format(dateFormat)
        : '',
      designationDate: values.designationDate
        ? moment(values.designationDate).format(dateFormat)
        : formattedDate,
      terminationDate: values.terminationDate
        ? moment(values.terminationDate).format(dateFormat)
        : null,
      confirmedBy: values.confirmedBy || '',
      annualLeaveCount: parseFloat(values.annualLeaveCount.toString()),
      casualLeaveCount: parseFloat(values.casualLeaveCount.toString()),
      medicalLeaveCount: 0,
      liueLeaveCount: parseFloat(values.liueLeaveCount.toString()),
      noPayLeaveCount: parseFloat(values.noPayLeaveCount.toString()),
      userName: values.employeeNickName,
      upn: values.upn,
      isActive: convertToBoolean(values.isActive),
      designation: values.designation,
      employeeExtensions: [
        {
          id: '',
          userId: '',
          extensionDate: formattedDate,
          period: 0,
          extendedBy: '',
          reason: ''
        }
      ]
    };

    if (formattedValues.status !== 'Terminated') {
      formattedValues.terminationDate = null;
      formattedValues.terminatedBy = '';
      formattedValues.terminatedReason = '';
    }

    if (selectedEmployee) {
      dispatch(updateEmployee(formattedValues)).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          navigate(`/admin`);
        }
      });
    } else {
      dispatch(createEmployee(formattedValues)).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          navigate(`/admin`);
        }
      });
    }
  };

  const getStatusOptions = () => {
    if (selectedEmployee) {
      return [
        ...statusOptions,
        { value: 'Terminated', label: 'Terminated' },
        { value: 'Resigned', label: 'Resigned' }
      ];
    }
    return statusOptions;
  };

  useEffect(() => {
    if (
      selectedEmployeeId &&
      location.pathname === `/admin/edit-employee/${selectedEmployeeId}`
    ) {
      if (selectedEmployee) setInitialValues(selectedEmployee);
    } else {
      setInitialValues(initialState);
    }
  }, [selectedEmployeeId]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values }: any) => (
        <Form>
          <div className="manage-employe-container">
            <div className="manage-employe-title">
              <Typography
                text="Employee registration"
                type="base_regular"
                weight="bold"
              />
            </div>

            <div className="manage-employe-form">
              <div className="registration-step-wrapper">
                <div className="step-number">1</div>
                <div className="step-info">
                  <Typography
                    text="Personal Information"
                    type="base_regular"
                    weight="bold"
                  />
                  <Typography
                    text="Enter basic details about the employee"
                    type="small_medium"
                    color="gray"
                  />
                </div>
              </div>

              <div className="registration-form">
                <Input
                  label="Full name"
                  name="employeeFullName"
                  placeholder="Enter full name"
                  inputWrapperClass="name-input-wrapper margin-24"
                  required
                />
                <Input
                  label="Name with initials"
                  name="employeeNameWithInitials"
                  placeholder="Enter name with initials"
                  inputWrapperClass="width-half margin-24"
                  required
                />
                <Input
                  label="Employee calling name"
                  name="employeeNickName"
                  placeholder="Enter employee calling name"
                  inputWrapperClass="width-half margin-24"
                  required
                />
                <Select
                  label="Active status"
                  name="isActive"
                  placeholder="Select employee active status"
                  inputWrapperClass="width-half margin-24"
                  required
                  options={employeeActiveStatus}
                />
                <DatePickerPro
                  label="Date of birth"
                  name="dateOfBirth"
                  required
                  inputWrapperClass="width-half margin-24"
                  maxDate={new Date()}
                />
                <Select
                  label="Gender"
                  name="gender"
                  placeholder="Select gender"
                  inputWrapperClass="width-half margin-24"
                  required
                  options={genderType}
                />
                <Input
                  label="NIC number"
                  name="nic"
                  placeholder="Enter nic number"
                  inputWrapperClass="width-half margin-24"
                  required
                />
                <Input
                  label="Passport number"
                  name="passportNumber"
                  placeholder="Enter passport number"
                  inputWrapperClass="width-half margin-24"
                />
                <Input
                  label="Mobile number"
                  name="mobileNumber"
                  placeholder="Enter mobile number"
                  inputWrapperClass="width-half margin-24"
                  required
                />
                <Input
                  label="Residencial phone number"
                  name="residentialPhoneNumber"
                  placeholder="Enter residencial phone number"
                  inputWrapperClass="width-half margin-24"
                />
                <Input
                  label="Personal email address"
                  name="personalEmail"
                  placeholder="Enter personal email address"
                  inputWrapperClass="width-half margin-24"
                />
                <Input
                  label="Religion"
                  name="religion"
                  placeholder="Enter religion"
                  inputWrapperClass="width-half margin-24"
                  required
                />
                <Input
                  label="Nationality"
                  name="nationality"
                  placeholder="Enter nationality"
                  inputWrapperClass="width-half margin-24"
                  required
                />
                <Select
                  label="Marital status"
                  name="maritalStatus"
                  placeholder="Select marital status"
                  inputWrapperClass="width-half margin-24"
                  required
                  options={maritalStatus}
                />
                <Input
                  label="UPN"
                  name="upn"
                  placeholder="Enter upn"
                  inputWrapperClass="width-half margin-24"
                  disabled={selectedEmployee ? true : false}
                  required
                />
                <Select
                  label="User role"
                  name="role"
                  placeholder="Select user role"
                  inputWrapperClass="width-half margin-24"
                  required
                  options={userRoles}
                />
                <Input
                  label="House number"
                  name="houseNumber"
                  placeholder="Enter house number"
                  inputWrapperClass="width-half margin-24"
                />
                <Input
                  label="Address"
                  name="address"
                  placeholder="Enter address"
                  inputWrapperClass="width-half margin-24"
                  required
                />
                <Input
                  label="District"
                  name="district"
                  placeholder="Enter district"
                  inputWrapperClass="width-half margin-24"
                />
                <Input
                  label="Province"
                  name="province"
                  placeholder="Enter province"
                  inputWrapperClass="width-half margin-24"
                />
              </div>
            </div>

            <div className="manage-employe-form">
              <div className="registration-step-wrapper">
                <div className="step-number">2</div>
                <div className="step-info">
                  <Typography
                    text="Employment Information"
                    type="base_regular"
                    weight="bold"
                  />
                  <Typography
                    text="Provide employment details for the employee"
                    type="small_medium"
                    color="gray"
                  />
                </div>
              </div>

              <div className="registration-form">
                <Input
                  label="Employee ID"
                  name="epfNumber"
                  placeholder="Enter employee ID"
                  inputWrapperClass="width-half margin-24"
                  required
                />
                <Select
                  label="Designation"
                  name="designation"
                  placeholder="Select employee designation"
                  inputWrapperClass="width-half margin-24"
                  required
                  options={designations}
                />
                <DatePickerPro
                  label="Joined date"
                  name="joinedDate"
                  required
                  inputWrapperClass="width-half margin-24"
                  maxDate={new Date()}
                />
                <DatePickerPro
                  label="Confirmation due date"
                  name="confirmationDate"
                  required
                  inputWrapperClass="width-half margin-24"
                />
                <Select
                  label="Status"
                  name="status"
                  placeholder="Enter status"
                  inputWrapperClass="width-half margin-24"
                  required
                  options={getStatusOptions()}
                />
                <Input
                  label="Tech Stack"
                  name="techStack"
                  placeholder="Enter tech stack"
                  inputWrapperClass="width-half margin-24"
                  required
                />

                {selectedEmployee && values.status === 'Terminated' && (
                  <>
                    <DatePickerPro
                      label="Termination date"
                      name="terminationDate"
                      required
                      inputWrapperClass="width-half margin-24"
                    />
                    <Select
                      label="Terminated by"
                      name="terminatedBy"
                      placeholder="Enter terminated by"
                      inputWrapperClass="width-half margin-24"
                      required
                      options={terminatedByOptions}
                    />
                    <TextAreaPro
                      label="Termination reason"
                      name="terminatedReason"
                      placeholder="Enter termination reason"
                      inputWrapperClass="width-half margin-24"
                      required
                    />
                  </>
                )}

                <Input
                  label="Annual leave count"
                  name="annualLeaveCount"
                  placeholder="Enter annual leave count"
                  inputWrapperClass="width-half margin-24"
                  required
                />
                <Input
                  label="Casual leave count"
                  name="casualLeaveCount"
                  placeholder="Enter casual leave count"
                  inputWrapperClass="width-half margin-24"
                  required
                />
                {/* <Input
                label="Medical leave count"
                name="medicalLeaveCount"
                placeholder="Enter medical leave count"
                inputWrapperClass="width-half margin-24"
                required
              /> */}
                <Input
                  label="Lieu leave count"
                  name="liueLeaveCount"
                  placeholder="Enter lieu leave count"
                  inputWrapperClass="width-half margin-24"
                  required
                />
              </div>
            </div>

            <div className="manage-employe-form">
              <div className="registration-step-wrapper">
                <div className="step-number">3</div>
                <div className="step-info">
                  <Typography
                    text="Emergency Contact"
                    type="base_regular"
                    weight="bold"
                  />
                  <Typography
                    text="Enter emergency contact details"
                    type="small_medium"
                    color="gray"
                  />
                </div>
              </div>

              <div className="registration-form">
                <Input
                  label="Emergency contact person"
                  name="emergencyContactPerson"
                  placeholder="Enter emergency contact person"
                  inputWrapperClass="width-half margin-24"
                  required
                />

                <Input
                  label="Emergency contact person number"
                  name="emergencyContactPersonPhoneNumber"
                  placeholder="Enter emergency contact person number"
                  inputWrapperClass="width-half margin-24"
                  required
                />

                <Input
                  label="Emergency contact person relationship"
                  name="emergencyContactPersonRelationship"
                  placeholder="Enter emergency contact person relationship"
                  inputWrapperClass="width-half margin-24"
                  required
                />
              </div>
            </div>
          </div>

          <div className="form-actions">
            <ButtonPro
              type="white"
              htmlType="button"
              label="Cancel"
              onClick={() => navigate(-1)}
            />
            <ButtonPro
              type="primary"
              htmlType="submit"
              label={selectedEmployee ? 'Update' : 'Register'}
              isLoading={
                createEmployeeStatus === ApiResponseStatus.pending ||
                updateEmployeeStatus === ApiResponseStatus.pending
              }
              disabled={
                createEmployeeStatus === ApiResponseStatus.pending ||
                updateEmployeeStatus === ApiResponseStatus.pending
              }
            />
          </div>

          <ScrollToError />
        </Form>
      )}
    </Formik>
  );
};
