import React from 'react';
import { AdminHeader, AdminSidebar, ButtonPro, Typography } from 'components';
import { RootState, useAppSelector } from 'store';
import { useParams, useNavigate } from 'react-router';
import './index.scss';
import moment from 'moment';

export const ViewEmployee = () => {
  const navigate = useNavigate();
  const { id: selectedEmployeeId } = useParams();

  const formatDate = (date: string) =>
    date ? moment(date).format('DD/MM/YYYY') : '';

  const selectedEmployee = useAppSelector((state: RootState) => {
    return selectedEmployeeId
      ? state.admin.employeeData.find(
          (employee) => employee.userRoleId === selectedEmployeeId
        )
      : null;
  });

  const designations = useAppSelector((state: RootState) => {
    return state.masterData.designations;
  });

  return (
    <>
      <AdminHeader />
      <div className="dashboard-wrapper">
        <AdminSidebar />
        <div className="manage-employe-container">
          <div className="manage-employe-title view-employee">
            <Typography
              text={`${
                selectedEmployee?.employeeNameWithInitials ||
                selectedEmployee?.upn
              } - ${selectedEmployee?.role}`}
              type="base_regular"
              weight="bold"
            />

            <ButtonPro
              type="primary"
              htmlType="button"
              label="Edit details"
              onClick={() => {
                navigate(
                  `/admin/edit-employee/${selectedEmployee?.userRoleId}`
                );
              }}
            />
          </div>

          <div className="manage-employe-form employee-info">
            <div className="registration-step-wrapper">
              <div className="step-number">1</div>
              <div className="step-info">
                <Typography
                  text="Personal Information"
                  type="base_regular"
                  weight="bold"
                />
                <Typography
                  text="Enter basic details about the employee"
                  type="small_medium"
                  color="gray"
                />
              </div>
            </div>

            <div className="employee-info-view">
              <div className="employee-info-row">
                <div className="employee-info-title">Full name</div>
                <div className="employee-info-value">
                  {selectedEmployee?.employeeFullName}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Name with initials</div>
                <div className="employee-info-value">
                  {selectedEmployee?.employeeNameWithInitials}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Active status</div>
                <div className="employee-info-value">
                  {selectedEmployee?.isActive ? 'Active' : 'Inactive'}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Date of birth</div>
                <div className="employee-info-value">
                  {selectedEmployee?.dateOfBirth
                    ? formatDate(selectedEmployee?.dateOfBirth)
                    : ''}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">NIC or Passport number</div>
                <div className="employee-info-value">
                  {selectedEmployee?.nic}
                </div>
              </div>
              {/* <div className="employee-info-row">
                <div className="employee-info-title">Passport number</div>
                <div className="employee-info-value">
                  {selectedEmployee?.passportNumber}
                </div>
              </div> */}
              <div className="employee-info-row">
                <div className="employee-info-title">Mobile number</div>
                <div className="employee-info-value">
                  {selectedEmployee?.mobileNumber}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">
                  Residential phone number
                </div>
                <div className="employee-info-value">
                  {selectedEmployee?.residentialPhoneNumber}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Email address</div>
                <div className="employee-info-value">
                  {selectedEmployee?.upn}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">
                  Personal email address
                </div>
                <div className="employee-info-value">
                  {selectedEmployee?.personalEmail}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">House number</div>
                <div className="employee-info-value">
                  {selectedEmployee?.houseNumber}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Address</div>
                <div className="employee-info-value">
                  {selectedEmployee?.address}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">District</div>
                <div className="employee-info-value">
                  {selectedEmployee?.district}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Province</div>
                <div className="employee-info-value">
                  {selectedEmployee?.province}
                </div>
              </div>
            </div>

            <div className="registration-step-wrapper">
              <div className="step-number">2</div>
              <div className="step-info">
                <Typography
                  text="Employment Information"
                  type="base_regular"
                  weight="bold"
                />
                <Typography
                  text="Enter basic details about the employee"
                  type="small_medium"
                  color="gray"
                />
              </div>
            </div>

            <div className="employee-info-view">
              <div className="employee-info-row">
                <div className="employee-info-title">Employee ID</div>
                <div className="employee-info-value">
                  {selectedEmployee?.epfNumber}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Designation</div>
                <div className="employee-info-value">
                  {
                    designations.find(
                      (designation) =>
                        designation.value === selectedEmployee?.designation
                    )?.label
                  }
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Joined date</div>
                <div className="employee-info-value">
                  {selectedEmployee?.joinedDate
                    ? formatDate(selectedEmployee?.joinedDate)
                    : ''}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Confirmation due date</div>
                <div className="employee-info-value">
                  {selectedEmployee?.confirmationDate
                    ? formatDate(selectedEmployee?.confirmationDate)
                    : ''}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Status</div>
                <div className="employee-info-value">
                  {selectedEmployee?.status}
                </div>
              </div>

              {selectedEmployee?.status === 'Terminated' ? (
                <>
                  <div className="employee-info-row">
                    <div className="employee-info-title">Termination date</div>
                    <div className="employee-info-value">
                      {selectedEmployee?.terminationDate
                        ? formatDate(selectedEmployee?.terminationDate)
                        : null}
                    </div>
                  </div>
                  <div className="employee-info-row">
                    <div className="employee-info-title">Terminated by</div>
                    <div className="employee-info-value">
                      {selectedEmployee?.terminatedBy}
                    </div>
                  </div>
                  <div className="employee-info-row">
                    <div className="employee-info-title">Terminated reason</div>
                    <div className="employee-info-value">
                      {selectedEmployee?.terminatedReason}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="employee-info-row">
                <div className="employee-info-title">Status</div>
                <div className="employee-info-value">
                  {selectedEmployee?.status}
                </div>
              </div>

              <div className="employee-info-row">
                <div className="employee-info-title">Annual leave count</div>
                <div className="employee-info-value">
                  {selectedEmployee?.annualLeaveCount}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Casual leave count</div>
                <div className="employee-info-value">
                  {selectedEmployee?.casualLeaveCount}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Medical leave count</div>
                <div className="employee-info-value">
                  {selectedEmployee?.medicalLeaveCount}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">Lieu leave count</div>
                <div className="employee-info-value">
                  {selectedEmployee?.liueLeaveCount}
                </div>
              </div>
            </div>

            <div className="registration-step-wrapper">
              <div className="step-number">3</div>
              <div className="step-info">
                <Typography
                  text="Emergency Contact Details"
                  type="base_regular"
                  weight="bold"
                />
                <Typography
                  text="Enter basic details about the employee"
                  type="small_medium"
                  color="gray"
                />
              </div>
            </div>

            <div className="employee-info-view">
              <div className="employee-info-row">
                <div className="employee-info-title">
                  Emergency contact person
                </div>
                <div className="employee-info-value">
                  {selectedEmployee?.emergencyContactPerson}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">
                  Emergency contact person number
                </div>
                <div className="employee-info-value">
                  {selectedEmployee?.emergencyContactPersonPhoneNumber}
                </div>
              </div>
              <div className="employee-info-row">
                <div className="employee-info-title">
                  Emergency contact person relationship
                </div>
                <div className="employee-info-value">
                  {selectedEmployee?.emergencyContactPersonRelationship}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
