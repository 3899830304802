import React from 'react';
import { Divider } from '../../atoms/Divider';
import './_section1.scss';

interface Prop {
  child1?: React.ReactNode;
  child2?: React.ReactNode;
}
export const Section1 = ({ child1, child2 }: Prop) => {
  return (
    <div className="section1_container">
      <div className="section1">
        <div className="left">{child1}</div>
        <div className="right">{child2}</div>
      </div>
      <Divider size="sm" orientation="hr" />
    </div>
  );
};
