import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IMasterData, IMasterDataObject } from 'models/models';
import { request } from 'services/apis';

export const getDesignations = createAsyncThunk(
  'masterData/getDesignations',
  async () => {
    try {
      const response = await request.get<IMasterDataObject[]>(
        '/metadata/designations'
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState: IMasterData = {
  designations: []
};

const masterDataSlice = createSlice({
  name: 'masterData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get designations
    builder.addCase(getDesignations.fulfilled, (state, { payload }) => {
      state.designations = payload;
    });
  }
});

const { reducer } = masterDataSlice;

export default reducer;
