import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { persistor, useAppDispatch } from 'store';
import { clearAuthState } from 'store/Auth';
import { InteractionStatus } from '@azure/msal-browser';
import { Spinner } from 'components/atoms';

export const Logout = () => {
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useAppDispatch();

  const purgeStore = async () => {
    await persistor.purge();
    dispatch(clearAuthState());
  };

  const logoutRequest = {
    account: accounts[0],
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL || ''
  };

  const aadLogout = async () => {
    await instance.logoutRedirect(logoutRequest).catch((e) => {
      console.error(e);
    });
  };

  useEffect(() => {
    const handleLogout = async () => {
      await purgeStore();
      if (inProgress === InteractionStatus.None) {
        await aadLogout();
      }
    };

    handleLogout();
  }, [inProgress]);

  return <Spinner showSpinner={true} loadingMessage="Logging out..." />;
};
