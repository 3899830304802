import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppSelector } from 'store';

export const ProtectedRouteUser = (props: any) => {
  const token = useAppSelector((state) => state.auth?.user?.authData?.token);

  if (!token) return <Navigate to="/login" />;

  return <Outlet {...props} />;
};
