import React from 'react';
import './_typography.scss';

interface Props {
  text: string;
  type?:
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'heading4'
    | 'heading5'
    | 'base_regular'
    | 'base_medium'
    | 'small_regular'
    | 'small_medium'
    | 'small_link'
    | 'small_bold'
    | 'xs_regular'
    | 'xs_medium'
    | 'logo_bold'
    | 'xx_small';
  color?: string | 'gray';
  weight?: 'bold';
  className?: string;
}

export const Typography = ({ text, type, color, weight, className }: Props) => {
  return (
    <div className="typography">
      <text className={`${type} || heading ${color} ${weight} ${className}`}>
        {text}
      </text>
    </div>
  );
};
