import * as React from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Typography, Button } from 'components/atoms';
import Modal from '@mui/material/Modal';
import './_modal.scss';

interface ModelProps {
  modalSize: 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
  showModal: boolean;
  onClose: () => void;
}

interface ModalHeaderProps {
  headerTitle: string;
  onClose: () => void;
}
interface ModalBodyProps {
  children: React.ReactNode;
}
interface ModalFooterProps {
  actionBtnText?: string;
  cancelBtnText?: string;
  onClose: () => void;
}

export const ModalHeader = (props: ModalHeaderProps) => {
  const { headerTitle, onClose } = props;
  return (
    <div className="header">
      <Typography text={headerTitle} type="heading4" />
      <IconButton
        className="close-btn"
        aria-label="close"
        onClick={onClose}
        sx={{
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export const ModalBody = (props: ModalBodyProps) => {
  const { children } = props;
  return <div className="body">{children}</div>;
};

export const ModalFooter = (props: ModalFooterProps) => {
  const { actionBtnText = 'Action', cancelBtnText = 'Cancel', onClose } = props;

  return (
    <div className="footer">
      <Button
        className="modal-option-btn"
        type="success"
        size="sm"
        onClick={() => {}}
      >
        {actionBtnText}
      </Button>
      <Button
        className="modal-option-btn"
        type="secondary"
        size="sm"
        onClick={onClose}
      >
        {cancelBtnText}
      </Button>
    </div>
  );
};

export const Model = (props: ModelProps) => {
  const { modalSize, children, showModal, onClose } = props;

  return (
    <>
      <Modal
        open={showModal}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={`modal-box ${modalSize}`}>{children}</Box>
      </Modal>
    </>
  );
};
