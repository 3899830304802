import React from 'react';
import { Typography } from 'components/atoms';
import './index.scss';

export const EmployeeSummaryFilter = () => {
  return (
    <div className="employee-summary-filter-container">
      <div className="employee-summary-title">
        <Typography text="Employee Summary" type="base_regular" weight="bold" />
      </div>
      {/* <div className="employee-summary-filter">
        <input type="text" />
        <button type="button">Filter</button>
        <button type="button">Export</button>
      </div> */}
    </div>
  );
};
