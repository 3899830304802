import React from 'react';
import './index.scss';
import { Typography } from 'components/atoms';

interface Props {
  requestCount: number;
  totalCount: number;
  summaryTitle: string;
  type:
    | 'summary_total'
    | 'summary_casual'
    | 'summary_medical'
    | 'summary_annual'
    | 'summary_lieu';
}

export const LeaveSummaryCard = ({
  requestCount,
  totalCount,
  type,
  summaryTitle
}: Props) => {
  return (
    <div className={`summary ${type}`}>
      <Typography text={summaryTitle} type="xs_medium" weight="bold" />
      <div className="outer">
        <div className="inner">
          <div className="inner_textual">
            <div className="inner_textual_numeric">
              <Typography
                type="heading5"
                text={
                  type !== 'summary_medical'
                    ? `${totalCount - requestCount}`
                    : `${requestCount}`
                }
              />
              {type !== 'summary_medical' && (
                <Typography type="small_bold" text={`/${totalCount}`} />
              )}
            </div>
            <Typography
              type="xx_small"
              text={type === 'summary_medical' ? 'Used' : 'Available'}
              color="gray"
            />
          </div>
        </div>
      </div>

      <svg className="svg" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <circle
          cx="55"
          cy="64"
          r="39.3"
          stroke-linecap="round"
          strokeDashoffset={(requestCount / totalCount) * 250}
        />
      </svg>
    </div>
  );
};
