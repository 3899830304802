import React from 'react';
import { useLocation, useParams } from 'react-router';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import './index.scss';

export const NewBreadCrumb = () => {
  const location = useLocation();
  const { leaveId } = useParams();

  let currentLink = '/';
  let crumbTitle = '';

  let split = location.pathname.split('/');
  if (split[0] === '' && split[1] === '') split = [''];

  const crumbs = split.map((crumb, index) => {
    if (crumb === leaveId) currentLink = currentLink + '/' + leaveId;
    else currentLink += `${crumb}`;
    crumbTitle = crumb.split('%20').join(' ');
    crumbTitle = crumbTitle.charAt(0).toUpperCase() + crumbTitle.slice(1);
    const isLastCrumb = index === split.length - 1;

    return (
      <>
        <div className="crumb" key={crumb}>
          <Link to={currentLink}>
            {' '}
            {crumb === ''
              ? 'Home'
              : crumb === 'respondLeaveRequest'
              ? 'Respond To Leave Request'
              : crumbTitle}{' '}
          </Link>
        </div>

        {!isLastCrumb && <KeyboardArrowRightIcon className="crumb-arrow" />}
      </>
    );
  });

  return <div className="crumbs">{crumbs}</div>;
};
