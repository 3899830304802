import React from 'react';
import './_icon.scss';

interface Props {
  onClick?: () => void;
  src: string;
  type: 'primary' | 'secondary' | 'danger' | 'white';
  disabled?: boolean;
  size?: 'small';
  customClassName?: string;
}
export const Icon = ({
  onClick,
  src,
  type,
  disabled,
  size,
  customClassName = ''
}: Props) => {
  if (disabled) onClick = () => {};
  return (
    <div
      className={`icon ${type} ${
        disabled ? 'disabled' : 'enabled'
      } ${size} ${customClassName}`}
      onClick={onClick}
    >
      <img src={src} alt="Icon" />
    </div>
  );
};
