import React from 'react';
import { AdminHeader, AdminSidebar } from 'components';
import './index.scss';

export const LeaveSummaryDashboard = () => {
  return (
    <>
      <AdminHeader />
      <div className="dashboard-wrapper">
        <AdminSidebar />
        <div className="leave-summery-dashboard-wrapper">
          <iframe
            title="LMS - Employee Leave Utilization"
            width="100%"
            height="100%"
            src="
https://app.powerbi.com/reportEmbed?reportId=79cd7c37-2a34-4726-a93c-f18197b5413b&autoAuth=true&ctid=7a4d6399-cacc-4f87-94ed-181bf634e133"
            allowFullScreen
          />
        </div>
      </div>
    </>
  );
};
