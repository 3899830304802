import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Login } from './components/pages/Login/Login';
import {
  AdminDashboard,
  ApproveLeaveRequest,
  Dashboard,
  IndividualLeaveInfo,
  Logout,
  AddEmployee,
  ViewEmployee,
  AdminLeaveSummary
} from 'components';
import { ProtectedRouteAdmin, ProtectedRouteUser } from 'providers';
import { LeaveSummaryDashboard } from 'components/pages/LeaveSummaryDashboard';
import { UserRoles } from 'models/enums';
import './styles/index.scss';
import { EmployeeSummaryDashboard } from 'components/pages/EmployeeSummaryDashboard';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />

          <Route element={<ProtectedRouteUser />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/LMS" element={<IndividualLeaveInfo />} />
          </Route>

          <Route
            element={<ProtectedRouteAdmin allowedRoles={[UserRoles.ADMIN]} />}
          >
            <Route
              path="/respondLeaveRequest"
              element={<ApproveLeaveRequest />}
            />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/add-employee" element={<AddEmployee />} />
            <Route path="/admin/edit-employee/:id" element={<AddEmployee />} />
            <Route path="/admin/view-employee/:id" element={<ViewEmployee />} />
          </Route>

          <Route
            element={
              <ProtectedRouteAdmin
                allowedRoles={[
                  UserRoles.MANAGER,
                  UserRoles.TEAM_LEAD,
                  UserRoles.ADMIN
                ]}
              />
            }
          >
            <Route
              path="/admin/leave-summary"
              element={<AdminLeaveSummary />}
            />
            <Route
              path="/admin/leave-summary-dashboard"
              element={<LeaveSummaryDashboard />}
            />
            <Route
              path="/admin/employee-summary-dashboard"
              element={<EmployeeSummaryDashboard />}
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
