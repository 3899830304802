import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Typography } from 'components';
import moment from 'moment';
import jwt from 'jwt-decode';
import { RootState, useAppSelector } from 'store';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import './index.scss';

export const AdminHeader = () => {
  const navigate = useNavigate();
  const buttonRef = useRef<any>(null);
  const dropDownRef = useRef<any>(null);

  const [currentTime, setCurrentTime] = useState(moment());
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);

  const token: any = useAppSelector((state: RootState) => {
    return state.auth?.user?.authData?.token
      ? jwt(state.auth.user.authData.token)
      : '';
  });

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        dropDownRef?.current &&
        buttonRef?.current &&
        !dropDownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsUserMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDownRef, buttonRef]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = currentTime.format('ddd DD MMMM hh:mm A');

  const logout = () => {
    navigate('/logout');
  };

  return (
    <div className="admin-header">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Portal</title>
      </Helmet>

      <div className="admin-brand">
        <img src="/icons/aventude.svg" alt="" />
      </div>

      <div className="admin-clock">
        <img src="/icons/clock.svg" alt="" />
        <span>{formattedTime}</span>
      </div>

      <div className="admin-header-user">
        {/* <div className="admin-notification">
          <button type="button" className="admin-notification-btn">
            <img src="/icons/notification-3-line.svg" alt="" />
          </button>
        </div> */}

        <div className="admin-user">
          {/* <img src="/icons/user.png" alt="" /> */}
          <Avatar name={token?.name} size="xsm" />
          <div className="admin-user-info">
            <Typography
              text={token?.name}
              type="small_regular"
              weight="bold"
              className="admin-user-name"
            />
            <Typography
              text="Admin"
              type="small_regular"
              className="admin-user-role"
            />
          </div>

          <button
            ref={buttonRef}
            type="button"
            className={`admin-user-btn ${isUserMenuVisible ? 'u-active' : ''}`}
            onClick={() => setIsUserMenuVisible(!isUserMenuVisible)}
          >
            <img src="/icons/chevron-down.svg" alt="" />
          </button>

          {isUserMenuVisible && (
            <div className="admin-user-menu" ref={dropDownRef}>
              <button onClick={logout} type="button">
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
