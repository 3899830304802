import React from 'react';
import './_button.scss';

interface ButtonType {
  children: React.ReactNode;
  size: 'xl' | 'lg' | 'base' | 'sm';
  type: 'primary' | 'secondary' | 'success' | 'dark';
  icon?: 'left' | 'right';
  iconSrc?: string;
  onClick?: () => void;
  htmlType?: 'button' | 'reset' | 'submit';
  className?: string;
  disabled?: boolean;
}

interface ButtonIconType {
  size: 'xl' | 'lg' | 'base' | 'sm';
  type: 'primary' | 'secondary' | 'success';
  iconSrc?: string;
}

export const ButtonIcon = (props: ButtonIconType) => {
  const { type, size, iconSrc } = props;

  return (
    <>
      <button className={`btn ${type} ${size}`}>
        <img className="icon" src={iconSrc} alt="button-icon" />
      </button>
    </>
  );
};

export const Button = (props: ButtonType) => {
  const {
    type,
    size,
    children,
    className,
    icon,
    iconSrc,
    onClick,
    htmlType = 'button',
    disabled
  } = props;

  let visibility = disabled ? 'disabled' : '';

  return (
    <>
      <button
        className={`btn ${type} ${size} ${className} ${visibility}`}
        onClick={onClick}
        type={htmlType}
        disabled={disabled}
      >
        {icon === 'left' ? (
          <img className="icon" src={iconSrc} alt="button-icon" />
        ) : (
          ''
        )}
        {children}
        {icon === 'right' ? (
          <img className="icon" src={iconSrc} alt="button-icon" />
        ) : (
          ''
        )}
      </button>
    </>
  );
};
