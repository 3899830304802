import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import {
  Button,
  DatepickerFormik,
  ModalBody,
  ModalHeader,
  Model,
  SelectInputFormik,
  Spinner,
  TextAreaFormik,
  Typography
} from 'components/atoms';
import './index.scss';
import { RootState, store, useAppDispatch, useAppSelector } from 'store';
import moment from 'moment';
import momentBusiness from 'moment-business-days';
import { getLeaveSummary, updateLeaveRequest } from 'store/Leaves';
import { DialogBox } from 'components/organisms/DialogBox';
import { Chip, IconButton } from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { DropZone } from 'components/molecules';
import dayjs from 'dayjs';

export const LeaveEditModal = ({ props }: any) => {
  const dispatch = useAppDispatch();
  const [noOfDays, setNoOfDays] = useState(-1);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [isSessionVisible, setIsSessionVisible] = useState(false);
  const [sessionVisibleFlag, setSessionVisibleFlag] = useState(false);
  const [session, setSession] = useState<any>(null);

  const [typeId, setTypeId] = useState<any>(null);

  // for setting initial values
  const [initialValues, setInitialValues] = useState(false);

  // for setting the status for loading spinner
  const [showSpinner, setShowSpinner] = useState(false);

  // for toggling the visibility of dropzone
  const [dropzone, setDropzone] = useState(false);

  // formData object for appending files
  const formData = new FormData();
  // for selecting files
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  // for storing the already existing files
  const [existingFiles, setExistngFiles] = useState([]);
  // determining maximum number of files allowed to attach
  const maxNoOfFiles = 3;

  // setting error message for attaching files
  const [errorMessage, setErrorMessage] = useState('');

  const status = useAppSelector((state: RootState) => {
    return state.leaves.status ? state.leaves.status : null;
  });
  const holidays = useAppSelector((state: RootState) => {
    return state.leaves.holidays ? state.leaves.holidays : [];
  });
  momentBusiness.updateLocale('si', {
    holidays: Array.isArray(holidays)
      ? holidays.map((item: any) =>
          moment(new Date(item.date)).format('YYYY-MM-DD')
        )
      : [],
    holidayFormat: 'YYYY-MM-DD'
  });

  // getting the remaining leave count to check whether no pay leave type needs to be shown
  const remainingLeaveCount = useAppSelector((state: RootState) => {
    return state.leaves.summary.totalRemainingLeaveCount
      ? state.leaves.summary.totalRemainingLeaveCount
      : null;
  });

  const { getState } = store;
  const { leaves } = getState();
  const { leaveTypeInfo } = leaves;

  let endInitialDate = moment
    .utc(props.endDate.toString())
    .utcOffset('+05:30')
    .format();
  endInitialDate = endInitialDate.slice(0, -15);

  let startInitialDate = moment
    .utc(props.startDate.toString())
    .utcOffset('+05:30')
    .format();
  startInitialDate = startInitialDate.slice(0, -15);

  const [leaveTypeId, setLeaveTypeId] = useState('');
  const [leaveSession, setLeaveSession] = useState<any>(null);

  // for setting leave type
  const [casualType, setCasualType] = useState('');
  const [lieuType, setLieuType] = useState('');
  const [medicalType, setMedicalType] = useState('');
  const [annualType, setAnnualType] = useState('');

  // for using the dialog box for edit
  const [confirm, setConfirm] = useState(true);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [values, setValues] = useState({});

  useEffect(() => {
    if (leaveTypeInfo) {
      leaveTypeInfo.length !== 0 &&
        leaveTypeInfo.map((info: any) => {
          switch (info.leaveType) {
            case 'Casual Leave':
              setCasualType(info.leaveTypeID);
              break;
            case 'Lieu Leave':
              setLieuType(info.leaveTypeID);
              break;
            case 'Medical Leave':
              setMedicalType(info.leaveTypeID);
              break;
            case 'Annual Leave':
              setAnnualType(info.leaveTypeID);
              break;
            default:
          }
        });
    }
  }, [leaveTypeInfo]);

  useEffect(() => {
    if (noOfDays > 1) {
      setIsSessionVisible(false);
      requestObj.leaveSession = 2;
    } else {
      if (noOfDays === 0) {
        setIsSessionVisible(false);
      } else if (
        noOfDays === 1 &&
        (leaveTypeId === casualType || leaveTypeId === lieuType) &&
        session === null &&
        typeId === null
      ) {
        setIsSessionVisible(true);
      } else {
        if (sessionVisibleFlag) {
          setIsSessionVisible(false);
          setSessionVisibleFlag(false);
        } else {
          if (typeId === null && noOfDays !== 0.5 && session === null) {
            setIsSessionVisible(false);
          } else if (typeId === null && noOfDays !== 0.5 && session !== null) {
            setIsSessionVisible(true);
          } else if (typeId === 1 || typeId === 3) {
            setIsSessionVisible(false);
          } else {
            setIsSessionVisible(true);
          }
        }
      }
    }
  }, [noOfDays]);

  useEffect(() => {}, [confirm, open]);
  const formInitialValues = {
    leaveTypeId: leaveTypeId,
    startDate: props.startDate,
    endDate: props.endDate,
    leaveSession: leaveSession,
    reason: props.reason
  };

  let tempDate = '';

  const validateFields = (values: any) => {
    const errors = {} as any;

    if (values.reason === '') errors.reason = 'Specify reason for leave';

    if (values.startDate === '')
      errors.startDate = 'Please select the start date';
    if (values.endDate === '') errors.endDate = 'Please select the end date';

    // checking whether the date selected is valid
    // current date values
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();

    // if (
    //   (values.startDate.$y < year ||
    //     (values.startDate.$y === year && values.startDate.$M < month) ||
    //     (values.startDate.$y === year &&
    //       values.startDate.$M === month &&
    //       values.startDate.$D < date)) &&
    //   momentBusiness([
    //     values.startDate.$y,
    //     values.startDate.$M,
    //     values.startDate.$D
    //   ]).businessDiff(momentBusiness([year, month, date])) > 2
    // )
    //   errors.startDate = 'Please select a valid start date';

    // if (
    //   (values.endDate.$y < year ||
    //     (values.endDate.$y === year && values.endDate.$M < month) ||
    //     (values.endDate.$y === year &&
    //       values.endDate.$M === month &&
    //       values.endDate.$D < date)) &&
    //   momentBusiness([
    //     values.endDate.$y,
    //     values.endDate.$M,
    //     values.endDate.$D
    //   ]).businessDiff(momentBusiness([year, month, date])) > 2
    // )
    //   errors.endDate = 'Please select a valid end date';
    // else if (values.endDate < values.startDate) {
    //   errors.endDate = 'Please select a valid end date';
    // }
    return errors;
  };

  const requestObj = {
    leaveTypeId:
      props.leaveType === 'Casual Leave'
        ? casualType
        : 'Medical Leave'
        ? medicalType
        : 'Lieu Leave'
        ? lieuType
        : 'Annual Leave'
        ? annualType
        : '',
    startDate: props.startDate,
    endDate: props.endDate,
    leaveSession: props.leaveSession,
    reason: props.reason
  };

  const updateLeave = (id: any, values: any) => {
    setId(id);
    setOpen(true);
    setValues(values);
  };

  // function for removing existing files
  const removeAttachedFiles = (removedFile: any) => {
    setExistngFiles(
      existingFiles.filter((file: any) => file.id !== removedFile)
    );
  };

  // function for operning an existing file
  const openExistingFile = (url: string) => {
    window.open(url, '_blank');
  };

  // setting error message
  useEffect(() => {
    if (props.leaveType === 'Medical Leave') {
      if (existingFiles.length + selectedFiles.length > maxNoOfFiles) {
        setErrorMessage('You exceeded the limit for attachments!');
        setDropzone(false);
      } else {
        setDropzone(true);
      }
      if (selectedFiles.length === 0 && errorMessage !== '') setDropzone(true);
    }
  }, [selectedFiles, existingFiles]);

  useEffect(() => {
    if (errorMessage !== '') {
      setSelectedFiles([]);
    } else {
      setDropzone(true);
    }
  }, [errorMessage]);

  const submitRequest = async (id: any) => {
    if (requestObj.leaveTypeId === annualType && requestObj.leaveSession !== 2)
      requestObj.leaveSession = 2;

    // setting fields of formData object
    formData.append('leaveTypeId', requestObj.leaveTypeId);
    formData.append('leaveSession', requestObj.leaveSession.toString());
    formData.append('startDate', requestObj.startDate);
    formData.append('endDate', requestObj.endDate);
    formData.append('reason', requestObj.reason);
    selectedFiles.map((file: any) => formData.append('files', file, file.name));
    existingFiles.map((file: any) =>
      formData.append('PreviousAttachments', file.name)
    );

    const data = { id, formData };
    setShowSpinner(true);

    await dispatch(updateLeaveRequest(data));

    if (status === 'fulfilled') {
      await dispatch(getLeaveSummary());
      setShowSpinner(false);
      setShowModal(false);
      setDropzone(false);
    } else if (status === 'rejected') {
      alert('Could not update the leave request!');
      setShowSpinner(false);
      setShowModal(false);
      setDropzone(false);
    }
  };

  const modalOnSubmit = (values: any) => {
    setConfirm(true);
    requestObj.leaveTypeId = leaveTypeId;
    tempDate = moment
      .utc(values.startDate.toString())
      .utcOffset('+05:30')
      .format();
    tempDate = tempDate.slice(0, -14);
    tempDate += '05:30:00.000Z';
    requestObj.startDate = tempDate;

    tempDate = moment
      .utc(values.endDate.toString())
      .utcOffset('+05:30')
      .format();
    tempDate = tempDate.slice(0, -14);
    tempDate += '05:30:00.000Z';
    requestObj.endDate = tempDate;

    if (noOfDays > 1) requestObj.leaveSession = 2;
    else requestObj.leaveSession = values.leaveSession;
    requestObj.reason = values.reason;

    setConfirm(true);
    if (confirm) {
      submitRequest(props.leaveId);
    }
  };

  const handleOpen = () => {
    setErrorMessage('');
    setExistngFiles(props.attachments);
    setNoOfDays(props.noOfdays);
    setSelectedFiles([]);
    if (props.leaveSession === 'Morning') {
      setLeaveSession(0);
    }
    if (props.leaveSession === 'Evening') {
      setLeaveSession(1);
    }
    if (props.leaveSession === 'FullDay') {
      setLeaveSession(2);
    }

    // setting the leaveTypeId according to the value passed
    switch (props.leaveType) {
      case 'Medical Leave':
        setLeaveTypeId(medicalType);
        setDropzone(true);
        break;
      case 'Lieu Leave':
        setLeaveTypeId(lieuType);
        setIsSessionVisible(false);
        setDropzone(false);
        break;
      case 'Casual Leave':
        setLeaveTypeId(casualType);
        setDropzone(false);
        break;
      case 'Annual Leave':
        setLeaveTypeId(annualType);
        setIsSessionVisible(false);
        setDropzone(false);
        break;
      default:
    }
    setInitialValues(true);
    setShowModal(true);
  };
  const handleClose = () => {
    setDropzone(false);
    setErrorMessage('');
    setShowModal(false);
  };

  const setEditLeaveSession = (setFieldValue: any, value: any) => {
    if (parseInt(value) === 0 || parseInt(value) === 1) {
      setNoOfDays(0.5);
    }
    setFieldValue('leaveSession', parseInt(value));
  };

  const calculateNoOfDays = (startDate: any, endDate: any) => {
    let tempStartDate = moment(new Date(startDate));
    let tempEndDate = moment(new Date(endDate));

    if (tempEndDate < tempStartDate) {
      setNoOfDays(0);
    } else {
      let totNoOfDays =
        momentBusiness([
          tempEndDate.year(),
          tempEndDate.month(),
          tempEndDate.date()
        ]).businessDiff(
          moment([
            tempStartDate.year(),
            tempStartDate.month(),
            tempStartDate.date()
          ])
        ) + 1;

      // start date is not a weekend, but end date is
      if (
        moment([
          tempStartDate.year(),
          tempStartDate.month(),
          tempStartDate.date()
        ]).day() < 6 &&
        (moment([
          tempEndDate.year(),
          tempEndDate.month(),
          tempEndDate.date()
        ]).day() === 6 ||
          moment([
            tempEndDate.year(),
            tempEndDate.month(),
            tempEndDate.date()
          ]).day() === 0)
      )
        totNoOfDays--;

      // both start date and end date are weekends
      if (
        (moment([
          tempStartDate.year(),
          tempStartDate.month(),
          tempStartDate.date()
        ]).day() === 6 ||
          moment([
            tempStartDate.year(),
            tempStartDate.month(),
            tempStartDate.date()
          ]).day() === 0) &&
        (moment([
          tempEndDate.year(),
          tempEndDate.month(),
          tempEndDate.date()
        ]).day() === 6 ||
          moment([
            tempEndDate.year(),
            tempEndDate.month(),
            tempEndDate.date()
          ]).day() === 0) &&
        moment([
          tempEndDate.year(),
          tempEndDate.month(),
          tempEndDate.date()
        ]).diff(
          moment([
            tempStartDate.year(),
            tempStartDate.month(),
            tempStartDate.date()
          ]),
          'days'
        ) <= 1
      ) {
        totNoOfDays = 0;
        setNoOfDays(totNoOfDays);
      } else if (totNoOfDays < 2) {
        if (session === null && typeId === null) {
          if (leaveSession === 0 || leaveSession === 1) {
            setNoOfDays(0.5);
          } else {
            setNoOfDays(1);
          }
        } else if (session !== null && typeId === null) {
          if (session === 2) {
            setNoOfDays(1);
          } else {
            setNoOfDays(0.5);
          }
        } else if (session === null && typeId !== null) {
          if (typeId === 0 || typeId === 2) {
            if (leaveSession === 2) {
              setNoOfDays(1);
            } else {
              setNoOfDays(0.5);
            }
          } else {
            setNoOfDays(1);
          }
        } else if (session !== null && typeId !== null) {
          if (typeId === 0 || typeId === 2) {
            if (session === 2) {
              setNoOfDays(1);
            } else {
              setNoOfDays(0.5);
            }
          } else {
            setNoOfDays(1);
          }
        }
      } else {
        setNoOfDays(totNoOfDays);
      }
    }
  };

  const setLeaveType = (setFieldValue: any, value: any) => {
    if (casualType === value) {
      setTypeId(0);
      setDropzone(false);
    }
    if (medicalType === value) {
      setTypeId(1);
      setDropzone(true);
    }
    if (lieuType === value) {
      setTypeId(2);
      setDropzone(false);
    }
    if (annualType === value) {
      setTypeId(3);
      setDropzone(false);
    }
    if (
      ((casualType === value || lieuType === value) && noOfDays === 1) ||
      ((casualType === value || lieuType === value) && noOfDays === 0.5)
    ) {
      if (session === null) {
        setNoOfDays(props.noOfdays);
      } else if (session === 1 || session === 0) {
        setNoOfDays(0.5);
      }
      setIsSessionVisible(true);
    } else if (
      (casualType !== value || lieuType !== value) &&
      noOfDays === 0.5
    ) {
      setSessionVisibleFlag(true);
      setNoOfDays(1);
    } else {
      setIsSessionVisible(false);
      requestObj.leaveSession = 2;
    }
    setFieldValue('leaveTypeId', value);
    setLeaveTypeId(value);
  };

  const setLeaveSessions = (setFieldValue: any, value: any) => {
    if (parseInt(value) === 0 || parseInt(value) === 1) {
      setSession(parseInt(value));
      setNoOfDays(0.5);
    } else {
      setSession(parseInt(value));
      setNoOfDays(1);
    }

    setFieldValue('leaveSession', parseInt(value));
    setLeaveSession(parseInt(value));
  };

  useEffect(() => {
    if (initialValues) {
      let initialLeaveSession;
      requestObj.leaveTypeId = leaveTypeId;
      requestObj.startDate = props.startDate;
      requestObj.endDate = props.endDate;
      if (props.leaveSession === 'Morning') {
        initialLeaveSession = 0;
      }
      if (props.leaveSession === 'Evening') {
        initialLeaveSession = 1;
      }
      if (props.leaveSession === 'FullDay') {
        initialLeaveSession = 2;
      }
      requestObj.leaveSession = initialLeaveSession;
      requestObj.reason = props.reason;
      setNoOfDays(props.noOfdays);
      if (initialLeaveSession === 0 || initialLeaveSession === 1) {
        setIsSessionVisible(true);
      }
      setTypeId(null);
      setSession(null);
      setSessionVisibleFlag(false);
      setInitialValues(false);
    }
  }, [initialValues]);

  const handleDisableDate = (date: any) => {
    const day = date.day();
    let isDisabled = false;
    if (holidays.length > 0) {
      isDisabled =
        holidays.some(
          (item: any) =>
            moment(new Date(item.date)).format('YYYY-MM-DD') ===
            moment(new Date(date.$d)).format('YYYY-MM-DD')
        ) ||
        day === 0 ||
        day === 6;
    }

    return isDisabled;
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        disabled={props.leaveType === 'Medical Leave'}
      >
        <DriveFileRenameOutlineIcon
          fontSize="medium"
          color={props.leaveType === 'Medical Leave' ? 'disabled' : 'action'}
        />
      </IconButton>

      <Model modalSize="lg" showModal={showModal} onClose={handleClose}>
        <Formik
          initialValues={formInitialValues}
          onSubmit={(values) => {
            updateLeave(id, values);
          }}
          validate={validateFields}
          validateOnChange
        >
          {({ values, setFieldValue }: any) => (
            <Form>
              <ModalHeader
                headerTitle="Leave Application Form"
                onClose={handleClose}
              />
              <ModalBody>
                <div className="leave-dates-pickers">
                  <div className="date-picker-start">
                    <DatepickerFormik
                      labelText="Leave Start:"
                      name="startDate"
                      required={true}
                      handleDisableDate={handleDisableDate}
                      defaultDate={startInitialDate}
                      handleChange={(event: any) => {
                        setFieldValue('startDate', event);
                        calculateNoOfDays(event, values.endDate);
                      }}
                    />
                  </div>
                  <div className="date-picker-end">
                    <DatepickerFormik
                      labelText="Leave End:"
                      name="endDate"
                      disabled={values.startDate === ''}
                      minDate={dayjs(values.startDate)}
                      handleDisableDate={handleDisableDate}
                      required={true}
                      defaultDate={endInitialDate}
                      handleChange={(event: any) => {
                        setFieldValue('endDate', event);
                        calculateNoOfDays(values.startDate, event);
                      }}
                    />
                  </div>
                </div>

                <div className="leave-select">
                  <SelectInputFormik
                    name="leaveTypeId"
                    label="Select leave type:"
                    error={false}
                    required={true}
                    handleChange={(event: any) => {
                      setLeaveType(setFieldValue, event.target.value);
                    }}
                    disabled={
                      noOfDays === 0 || props.leaveType === 'Medical Leave'
                    }
                  >
                    {leaveTypeInfo ? (
                      leaveTypeInfo.length !== 0 &&
                      leaveTypeInfo.map((info: any) => {
                        // no pay leave type is shown only when the remaining leave count is 0
                        if (
                          info.leaveType === 'Nopay Leave' &&
                          remainingLeaveCount !== 0
                        )
                          return <></>;
                        else
                          return (
                            <option value={info.leaveTypeID}>
                              {info.leaveType}
                            </option>
                          );
                      })
                    ) : (
                      <option value="">Select Leave Type</option>
                    )}
                  </SelectInputFormik>
                </div>

                {isSessionVisible && (
                  <div className="leave-select">
                    <SelectInputFormik
                      name="leaveSession"
                      label="Select leave session:"
                      error={false}
                      required={true}
                      handleChange={(event: any) => {
                        setEditLeaveSession(setFieldValue, event.target.value);
                        setLeaveSessions(setFieldValue, event.target.value);
                      }}
                      disabled={noOfDays === 0}
                    >
                      <option selected value={0}>
                        Morning Session
                      </option>
                      <option value={1}>Evening Session</option>
                      <option value={2}>Full Day</option>
                    </SelectInputFormik>
                  </div>
                )}

                <Typography
                  type="small_bold"
                  text={`Number of days selected: ${noOfDays.toString()}`}
                />

                <div className="leave-desc">
                  <TextAreaFormik
                    name="reason"
                    id="sample-textarea"
                    rows={5}
                    label="Leave Description:"
                    placeholder="Leave Description"
                    required={true}
                    handleChange={(event: any) =>
                      setFieldValue('reason', event.target.value)
                    }
                    disabled={noOfDays === 0}
                  />
                </div>

                {existingFiles && dropzone && (
                  <div className="existing-file-list">
                    {existingFiles.map((attachment: any) => (
                      <>
                        <div className="chip_color">
                          <Chip
                            label={attachment.name}
                            onDelete={() => removeAttachedFiles(attachment.id)}
                            onClick={() =>
                              openExistingFile(
                                process.env.REACT_APP_BLOB_STORAGE_URL +
                                  attachment.name
                              )
                            }
                            clickable
                            variant="outlined"
                          />
                        </div>
                      </>
                    ))}
                  </div>
                )}

                {dropzone && <DropZone setSelectedFiles={setSelectedFiles} />}

                {errorMessage !== '' && (
                  <Typography text={errorMessage} type="small_regular" />
                )}

                <div className="modal_footer_buttons">
                  {showSpinner ? (
                    <Button
                      className="modal-option-btn"
                      type="success"
                      disabled={true}
                      size="base"
                      htmlType="submit"
                      onClick={() => {}}
                    >
                      <Spinner showSpinner={showSpinner} type="btn_spinner" />
                    </Button>
                  ) : (
                    <Button
                      className="modal-option-btn"
                      type="success"
                      size="base"
                      htmlType="submit"
                      onClick={() => {}}
                      disabled={
                        props.leaveType === 'Medical Leave'
                          ? existingFiles.length === 0 &&
                            selectedFiles.length === 0
                          : false
                      }
                    >
                      Apply
                    </Button>
                  )}
                  <Button
                    className="modal-option-btn"
                    type="secondary"
                    size="base"
                    onClick={() => {
                      setShowModal(false);
                      setErrorMessage('');
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalBody>
            </Form>
          )}
        </Formik>
        <DialogBox
          titleMessage="Are you sure you want to edit this leave request?"
          confirm={confirm}
          setConfirm={setConfirm}
          setOpen={setOpen}
          open={open}
          handleConfirm={() => {
            setConfirm(true);
            modalOnSubmit(values);
            setOpen(false);
          }}
        />
      </Model>
    </>
  );
};
