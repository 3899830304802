import React from 'react';

import { Typography } from 'components/atoms';

import './index.scss';

interface Props {
  title: string;
  subtitle: string;
  type: string;
  icon: any;
  handleNavigation: (type: string) => void;
}
export const DashboardTile = (props: Props) => {
  const { title, subtitle, icon, handleNavigation, type } = props;

  return (
    <div className="dashboard_tile" onClick={() => handleNavigation(type)}>
      <div className="tile-icon">{icon}</div>
      <div className="tile-text">
        <Typography text={title} type="heading4" weight="bold" />
        <Typography
          text={subtitle}
          type="base_medium"
          className="tile-sub-text"
        />
      </div>
    </div>
  );
};
