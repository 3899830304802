import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Field, ErrorMessage, useField } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

interface IProps {
  name: string;
  id?: string;
  label: string;
  placeholder?: string;
  inputWrapperClass?: string;
  inputClass?: string;
  required?: boolean;
  maxDate?: Date;
  onDateChange?: (date: Date | null) => void;
}

const parseDate = (dateString: string | null | undefined): Date | null => {
  if (!dateString || dateString === '0001-01-01T00:00:00') {
    return null;
  }
  return new Date(dateString);
};

export const DatePickerPro = ({
  name,
  id,
  label,
  placeholder = 'Select Date',
  inputWrapperClass,
  inputClass,
  required,
  maxDate,
  onDateChange
}: IProps) => {
  const [field, meta] = useField(name);

  const [startDateL, setstartDateL] = useState<Date | null | undefined>(null);
  const [endDateL, setendDateL] = useState<Date | null | undefined>(null);
  const [shouldCloseOnSelect, setShouldCloseOnSelect] = useState(true);
  const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
  const [showYearPicker, setShowYearPicker] = useState(false);

  const showNextBtn = (
    customHeaderCount: number,
    monthsShown: number,
    disabled: boolean
  ) => {
    if ((!disabled && customHeaderCount === 1) || monthsShown === 1) {
      return true;
    }
    return false;
  };

  const onChange = (dates: Date | null) => {
    onDateChange && onDateChange(dates);
    if (showMonthYearPicker || showYearPicker) {
      setShowYearPicker(false);
      setShowMonthYearPicker(false);
      setShouldCloseOnSelect(true);
    }
  };

  return (
    <div className={`date-picker-container ${inputWrapperClass}`}>
      <Field name={name}>
        {({ field, form }: any) => {
          const { setFieldValue } = form;
          const { value } = field;

          const parsedValue = parseDate(value);

          return (
            <>
              <label className="input-label">
                {label}
                {required ? <span className="input-required">*</span> : null}
              </label>
              <DatePicker
                onCalendarClose={() => {
                  setShowYearPicker(false);
                  setShowMonthYearPicker(false);
                  setShouldCloseOnSelect(true);
                }}
                placeholderText={placeholder}
                selected={parsedValue}
                isClearable
                className={meta.touched && meta.error ? 'input-error' : ''}
                startDate={startDateL}
                endDate={endDateL}
                name={name}
                onChange={(date) => {
                  setFieldValue(name, date);
                  onChange(date);
                }}
                dateFormat="MMMM dd, yyyy"
                formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3)}
                shouldCloseOnSelect={shouldCloseOnSelect}
                showMonthYearPicker={showMonthYearPicker}
                showFullMonthYearPicker
                autoComplete = "off"
                showYearPicker={showYearPicker}
                renderCustomHeader={({
                  monthDate,
                  customHeaderCount,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                  decreaseYear,
                  increaseYear,
                  prevYearButtonDisabled,
                  nextYearButtonDisabled
                }) => (
                  <div className="datepicker-header">
                    {showYearPicker ? (
                      !prevYearButtonDisabled && (
                        <i
                          onClick={decreaseYear}
                          className="month-button-prev"
                          role="presentation"
                        >
                          <img src="/icons/arrow-left-line.svg" />
                        </i>
                      )
                    ) : !prevMonthButtonDisabled && customHeaderCount === 0 ? (
                      <i
                        onClick={decreaseMonth}
                        className="month-button-prev"
                        role="presentation"
                      >
                        <img src="/icons/arrow-left-line.svg" />
                      </i>
                    ) : (
                      <div style={{ width: '24px' }} />
                    )}

                    <div className="month-year">
                      <h5
                        className="react-datepicker__current-month"
                        onClick={() => {
                          setShowYearPicker(false);
                          setShowMonthYearPicker(!showMonthYearPicker);
                          setShouldCloseOnSelect(false);
                        }}
                        role="presentation"
                      >
                        {monthDate?.toLocaleString('en-US', {
                          month: 'long'
                        })}
                      </h5>
                      <h5
                        className="react-datepicker__current-month"
                        onClick={() => {
                          setShowMonthYearPicker(false);
                          setShowYearPicker(!showYearPicker);
                          setShouldCloseOnSelect(false);
                        }}
                        role="presentation"
                      >
                        {monthDate?.toLocaleString('en-US', {
                          year: 'numeric'
                        })}
                      </h5>
                    </div>
                    {showYearPicker ? (
                      !nextYearButtonDisabled && (
                        <i
                          onClick={increaseYear}
                          className="month-button-next"
                          role="presentation"
                        >
                          <img src="/icons/arrow-right-line.svg" />
                        </i>
                      )
                    ) : showNextBtn(
                        customHeaderCount,
                        1,
                        nextMonthButtonDisabled
                      ) ? (
                      <i
                        onClick={increaseMonth}
                        className="month-button-next"
                        role="presentation"
                      >
                        <img src="/icons/arrow-right-line.svg" />
                      </i>
                    ) : (
                      <div style={{ width: '24px' }} />
                    )}
                  </div>
                )}
              />
            </>
          );
        }}
      </Field>
      <ErrorMessage name={name} component="div" className="error" />
    </div>
  );
};
