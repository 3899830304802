import React from 'react';
import './_badge.scss';

interface Props {
  label: React.ReactNode;
  size: 'lg' | 'sm';
  type: 'primary' | 'secondary' | 'success' | 'warning' | 'danger';
}

export const Badge = (props: Props) => {
  const { label, size, type } = props;
  return <div className={`badge ${size} ${type}`}> {label} </div>;
};
