import React from 'react';
import './_mainArea.scss';

interface Prop {
  child1?: React.ReactNode;
  child2?: React.ReactNode;
  child3?: React.ReactNode;
  mode?: string;
}

export const MainArea = ({ child1, child2, child3, mode }: Prop) => {
  return (
    <div className={mode === 'dashboard' ? 'dashboard-main-area' : 'mainArea'}>
      {child1}
      {child2}
      {child3}
    </div>
  );
};
