import React from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import './index.scss';

interface IProps {
  name: string;
  id?: string;
  label: string;
  placeholder?: string;
  inputWrapperClass?: string;
  inputClass?: string;
  required?: boolean;
  options: Array<{ value: string; label: string }>;
}

export const Select = ({
  name,
  id,
  label,
  placeholder = 'Select an option',
  inputWrapperClass,
  inputClass,
  required,
  options
}: IProps) => {
  const [field, meta] = useField(name);

  return (
    <div className={`input-select-wrapper ${inputWrapperClass}`}>
      <label htmlFor={id || name} className="input-label">
        {label}
        {required ? <span className="input-required">*</span> : null}
      </label>
      <select
        className={`input-box ${inputClass} ${
          meta.touched && meta.error ? 'input-error' : ''
        }`}
        {...field}
        id={id || name}
      >
        <option value="" label={placeholder} />
        {options.map((option) => (
          <option key={option.value} value={option.value} label={option.label}>
            {option.label}
          </option>
        ))}
      </select>
      <ErrorMessage name={name} component="div" className="error" />
    </div>
  );
};
