import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './index.scss';
import { Typography } from 'components/atoms';

interface IDropzone {
  setSelectedFiles: any;
}
export const DropZone = ({ setSelectedFiles }: IDropzone) => {
  // for setting the files inserted to the drop zone
  const [files, setFiles] = useState([]);
  // for setting the maximum number of files allowed to upload
  const maxNoOfFiles = 3;

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'application/pdf': ['.pdf']
    },
    maxFiles: maxNoOfFiles,
    maxSize: 1048576 * 10, // 10MB -> 1024 * 1024 * 10 bytes (maxSize value should be in bytes)
    onDrop: (acceptedFiles: any) => {
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );

      setSelectedFiles(acceptedFiles);
    }
  });

  const preview = files.map((file: any) => {
    let previewName =
      file.name && file.name.length <= 15
        ? file.name
        : file.name.slice(0, 20) + '...';
    return file.type !== 'application/pdf' ? (
      <div id="preview">
        <img src={file.preview} alt="file preview" />
        <Typography
          key={file.path}
          text={`${previewName} - ${
            file.size > 1024 * 1024
              ? `${Math.floor(file.size / (1024 * 1024))} MB`
              : file.size > 1024
              ? `${Math.floor(file.size / 1024)} KB`
              : `${file.size} bytes`
          }`}
          type="small_medium"
        />
      </div>
    ) : (
      <div id="preview">
        <Typography
          key={file.path}
          text={`${previewName} - ${
            file.size > 1024 * 1024
              ? `${Math.floor(file.size / (1024 * 1024))} MB`
              : file.size > 1024
              ? `${Math.floor(file.size / 1024)} KB`
              : `${file.size} bytes`
          }`}
          type="small_medium"
        />
      </div>
    );
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div className="dropzone_section">
      <Typography text="Attachment:" type="small_bold" />
      <div
        {...getRootProps({
          className: `baseStyle ${
            isFocused
              ? 'focusedStyle'
              : isDragAccept
              ? 'acceptStyle'
              : isDragReject
              ? 'rejectStyle'
              : ''
          } `
        })}
      >
        <input {...getInputProps()} />
        {isDragReject && fileRejections.length <= maxNoOfFiles ? (
          <p> File type is not supported!</p>
        ) : fileRejections.length > maxNoOfFiles ? (
          <p> You cannot upload more than {maxNoOfFiles} files </p>
        ) : (
          <div id="dropzone">
            <p>
              Choose files from your computer, or drag and drop files here.{' '}
            </p>
            {acceptedFiles.length !== 0 && (
              <Typography text="Preview" type="xs_medium" weight="bold" />
            )}
            <div className="preview_list">{preview}</div>
          </div>
        )}
      </div>
    </div>
  );
};
