/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  CustomDatePicker,
  Divider,
  Icon,
  Spinner,
  Typography
} from 'components/atoms';
import { NewBreadCrumb } from 'components/molecules';
import { ViewScreen } from 'components/template';
import { Pagination } from 'components/organisms';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import {
  getLeaveSummary,
  sortLeaves,
  deleteLeaveRequest,
  resetFilter,
  filterLeaves,
  searchLeaves,
  getLeaveTypes,
  getPublicHolidays
} from 'store/Leaves';
import { LeaveApplyModal } from 'components/template/LeaveApplyModal';
import { LeaveEditModal } from 'components/template/LeaveEditModal';
import './indiviaualLeaveInformation.scss';
import moment from 'moment';
import { ApiResponseStatus } from 'models/enums';
import 'react-toastify/dist/ReactToastify.css';
import { DialogBox } from 'components/organisms/DialogBox';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { IconButton } from '@mui/material';
import { LeaveSummaryCard } from 'components/organisms/LeaveSummaryCard';

export const IndividualLeaveInfo = () => {
  const dispatch = useAppDispatch();

  const getLeaveTypeInfo = () => {
    dispatch(getLeaveTypes());
    dispatch(getPublicHolidays());
  };

  const leaves = useAppSelector((state: RootState) => {
    return state.leaves.summary ? state.leaves.summary.leaveSummary : null;
  });

  const masterDataStatus = useAppSelector((state: RootState) => {
    return state.leaves.masterDataStatus ? state.leaves.masterDataStatus : null;
  });

  // for getting requested, remaining, and total leave count of each leave type
  const allocatedLeaves = useAppSelector((state: RootState) => {
    return state.leaves.summary ? state.leaves.summary.allocationLeaves : null;
  });
  // for getting total leave count
  const totalLeaveCount = useAppSelector((state: RootState) => {
    return state.leaves.summary ? state.leaves.summary.totalLeaveCount : null;
  });
  // for getting total used leave count
  const totalUsedLeaveCount = useAppSelector((state: RootState) => {
    return state.leaves.summary
      ? state.leaves.summary.totalUsedLeaveCount
      : null;
  });

  // for calculating the total number of leaves
  const [totalLeaves, setTotalLeaves] = useState(0);
  const calcTotalLeaves = () => {
    let total = 0;
    if (leaves) {
      leaves.forEach((leave: any) => {
        if (leave.leaveStatus !== 'Rejected')
          total += parseFloat(leave.noOfdays);
      });
      setTotalLeaves(total);
    }
  };

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [optionValue, setOptionValue] = useState('');

  // for setting the status for loading spinner
  const [showSpinner, setShowSpinner] = useState(false);

  // for deleting leave request
  const [confirm, setConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);

  useEffect(() => {
    // dispatch the function for receiving individual leave information
    getLeaveTypeInfo();
    dispatch(getLeaveSummary());
  }, []);

  // for calculating the total number of leaves
  useEffect(() => {
    calcTotalLeaves();
  }, [leaves]);

  const status = useAppSelector((state: RootState) => {
    return state.leaves.status ? state.leaves.status : null;
  });

  const filterByType = useAppSelector((state: RootState) => {
    return state.leaves.filterType ? state.leaves.filterType : null;
  });
  const startDate = useAppSelector((state: RootState) => {
    return state.leaves.startDate ? state.leaves.startDate : null;
  });
  const endDate = useAppSelector((state: RootState) => {
    return state.leaves.endDate ? state.leaves.endDate : null;
  });

  // newest filter method
  const setFilter = (value: any) => {
    dispatch(filterLeaves(value));
  };

  // newest search method
  const setSearch = (value: any) => {
    dispatch(searchLeaves(value));
  };

  // delete action dispatch
  const deleteLeave = (id: any) => {
    setId(id);
    setOpen(true);
  };

  const deleteFunction = async (id: any) => {
    await dispatch(deleteLeaveRequest(id));
    if (status === ApiResponseStatus.fulfilled) {
      await dispatch(getLeaveSummary());
      setShowSpinner(false);
    }
    setOpen(false);
  };

  const headerItem1 = (
    <div>
      <Typography type="heading5" text="Aventude Pvt Ltd" />
      <NewBreadCrumb />
    </div>
  );

  const headerItem2 = <LeaveApplyModal />;

  const mainItem1 = (
    <>
      <div className="mainItem1_summary_cards">
        {totalLeaveCount && (
          <LeaveSummaryCard
            requestCount={totalUsedLeaveCount}
            totalCount={totalLeaveCount}
            type="summary_total"
            summaryTitle="Total Leave"
          />
        )}
        <div id="summary_each_type">
          {allocatedLeaves &&
            allocatedLeaves.map((leaveType: any) =>
              leaveType.leaveType !== 'Nopay Leave' ? (
                <LeaveSummaryCard
                  requestCount={leaveType.usedCount}
                  totalCount={leaveType.totalCount}
                  type={
                    leaveType.leaveType === 'Lieu Leave'
                      ? 'summary_lieu'
                      : leaveType.leaveType === 'Medical Leave'
                      ? 'summary_medical'
                      : leaveType.leaveType === 'Casual Leave'
                      ? 'summary_casual'
                      : leaveType.leaveType === 'Annual Leave'
                      ? 'summary_annual'
                      : 'summary_total'
                  }
                  summaryTitle={leaveType.leaveType}
                />
              ) : (
                <></>
              )
            )}
        </div>
      </div>
      <Divider orientation="hr" size="sm" />
      <div className="mainItem1">
        <div className="mainItem1_left">
          <div className="filter_by_leave_type_section">
            <Typography text="Filter by Leave Type" type="small_medium" />
            <select
              id="filter_by_type"
              className="leave_type_filter_section"
              value={filterByType || 'All'}
              onChange={(e) => {
                // using newest filter method
                setFilter({
                  filterByType: e.target.value,
                  startDate: startDate,
                  endDate: endDate
                });
              }}
            >
              <option value="All">All Leave types</option>
              <option value="Casual Leave">Casual</option>
              <option value="Medical Leave">Medical</option>
              <option value="Lieu Leave">Lieu</option>
              <option value="Annual Leave">Annual</option>
            </select>
          </div>
          <div className="date_range_filter_section">
            <CustomDatePicker
              labelText="From"
              onChange={(e) => {
                setFrom(e.target.value);
              }}
              defaultDate={from}
            />

            <CustomDatePicker
              labelText="To"
              onChange={(e) => setTo(e.target.value)}
              defaultDate={to}
            />

            <Button
              size="sm"
              type="secondary"
              disabled={from === '' || (to !== '' && from > to)}
              onClick={() => {
                // using the newest filter method
                setFilter({
                  filterByType: filterByType,
                  startDate:
                    from === ''
                      ? moment(new Date(0)).format('YYYY-MM-DD')
                      : from,
                  endDate:
                    to === ''
                      ? moment(new Date(8640000000000000)).format('YYYY-MM-DD')
                      : to
                });
              }}
            >
              Filter by date range
            </Button>
          </div>
          <Button
            disabled={
              (filterByType === 'All' || filterByType === null) && from === ''
            }
            type="secondary"
            size="sm"
            onClick={() => {
              dispatch(resetFilter());
              setFrom('');
              setTo('');
              setOptionValue('All');
            }}
          >
            <RefreshIcon className="reset_button" />
            Reset filtering
          </Button>
          <div className="gapping_element"></div>
          <Divider orientation="vr" size="lg" />
          <div className="search_by_descriptoin_section">
            <SearchIcon height={24} />
            <input
              type="text"
              placeholder="Search by description"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={'mainItem1_right'}>
          <Icon type="primary" src="/icons/bars.svg" disabled />
          <Typography
            type="small_bold"
            text={
              totalLeaves === 1
                ? `${totalLeaves} Leave`
                : totalLeaves === null
                ? '0 Leave'
                : `${totalLeaves} Leave`
            }
          />
        </div>
      </div>
    </>
  );

  const columns = [
    { accessor: 'leaveType', label: 'Leave Type' },
    { accessor: 'reason', label: 'Leave Description' },
    { accessor: 'leaveSession', label: 'Leave Session' },
    { accessor: 'noOfdays', label: 'Applied Leaves' },
    { accessor: 'startDate', label: 'From' },
    { accessor: 'endDate', label: 'To' },
    { accessor: 'leaveStatus', label: 'Status' },
    { accessor: 'adminComment', label: 'HR Comments' },
    { accessor: 'action', label: 'Action' }
  ];

  const Table = (
    <div className="table_wrapper">
      <table>
        <thead>
          <tr>
            {columns.map((column) => {
              return (
                <th
                  key={column.accessor}
                  onClick={() => dispatch(sortLeaves(column.accessor))}
                >
                  <div className="th_padding">
                    <Typography type="small_bold" text={column.label} />
                    {column.accessor !== 'leaveType' &&
                    column.accessor !== 'action' ? (
                      <div>
                        <Icon
                          type="secondary"
                          src="/icons/angle_up.svg"
                          size="small"
                        />
                        <Icon
                          type="secondary"
                          src="/icons/angle_down.svg"
                          size="small"
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {leaves ? (
            leaves.map((row: any) => {
              return (
                <tr key={row.leaveId}>
                  {columns.map((column) => {
                    return column.accessor === 'action' &&
                      row['leaveStatus'] === 'Pending' ? (
                      <td className="td_space_between">
                        <LeaveEditModal props={row} />
                        <IconButton onClick={() => deleteLeave(row.leaveId)}>
                          <DeleteOutlineOutlinedIcon
                            fontSize="medium"
                            className="delete_icon"
                          />
                        </IconButton>
                      </td>
                    ) : column.accessor === 'action' &&
                      row['leaveStatus'] !== 'Pending' ? (
                      <td className="td_space_between">
                        <IconButton disabled={true}>
                          <DriveFileRenameOutlineIcon
                            fontSize="medium"
                            color="disabled"
                          />
                        </IconButton>
                        <IconButton disabled={true}>
                          <DeleteOutlineOutlinedIcon
                            fontSize="medium"
                            color="disabled"
                          />
                        </IconButton>
                      </td>
                    ) : column.accessor === 'leaveStatus' ? (
                      row[column.accessor] === 'Approved' ? (
                        <td key={column.accessor}>
                          <Badge label="Approved" size="sm" type="success" />
                        </td>
                      ) : row[column.accessor] === 'Pending' ? (
                        <td key={column.accessor}>
                          <Badge label="Pending" size="sm" type="primary" />
                        </td>
                      ) : (
                        <td key={column.accessor}>
                          <Badge label="Rejected" size="sm" type="danger" />
                        </td>
                      )
                    ) : column.accessor === 'startDate' ||
                      column.accessor === 'endDate' ? (
                      <td key={column.accessor}>
                        <Typography
                          type="small_regular"
                          text={moment(row[column.accessor]).format(
                            'MMMM DD YYYY'
                          )}
                        />
                      </td>
                    ) : (
                      <td key={column.accessor}>
                        <Typography
                          type="small_regular"
                          text={row[column.accessor]}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr></tr>
          )}
          <DialogBox
            titleMessage="Are you sure you want to delete this leave request?"
            confirm={confirm}
            setConfirm={setConfirm}
            setOpen={setOpen}
            open={open}
            handleConfirm={() => deleteFunction(id)}
          />
        </tbody>
      </table>
    </div>
  );

  return (
    <>
      {masterDataStatus === 'pending' && (
        <Spinner showSpinner loadingMessage={'Loading, Please Wait...'} />
      )}
      {masterDataStatus === 'fulfilled' && (
        <ViewScreen
          headerItem1={headerItem1}
          headerItem2={headerItem2}
          mainItem1={mainItem1}
          mainItem2={Table}
          mainItem3={<Pagination />}
        />
      )}
    </>
  );
};
