import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  titleMessage: string;
  confirm?: any;
  setConfirm: any;
  open: any;
  setOpen: any;
  handleConfirm?: any;
}

export const DialogBox = (props: Props) => {
  const { titleMessage, open, setOpen, handleConfirm } = props;

  const handleSubmit = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{titleMessage}</DialogTitle>
        <DialogActions>
          <Button
            onClick={handleConfirm ? handleConfirm : handleSubmit}
            autoFocus
          >
            OK
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
