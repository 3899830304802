import React from 'react';
import { HeaderArea } from '../../organisms/HeaderArea';
import { MainArea } from '../../organisms/MainArea';
import './index.scss';

interface Props {
  headerItem1?: React.ReactNode;
  headerItem2?: React.ReactNode;
  mainItem1?: React.ReactNode;
  mainItem2?: React.ReactNode;
  mainItem3?: React.ReactNode;
  portalTitle?: string;
  mode?: string;
}
export const ViewScreen = ({
  headerItem1,
  headerItem2,
  mainItem1,
  mainItem2,
  mainItem3,
  portalTitle,
  mode
}: Props) => {
  return (
    <div className="viewScreen_hero">
      <HeaderArea
        portalTitle={portalTitle}
        child1={headerItem1}
        child2={headerItem2}
      />
      <MainArea
        mode={mode}
        child1={mainItem1}
        child2={mainItem2}
        child3={mainItem3}
      />
    </div>
  );
};
