// This is a sample store for the fact API (Testing purpose)

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth, AuthRequest } from '../../models/models';
import { ApiResponseStatus } from '../../models/enums';
import { request } from 'services/apis';
import { toast } from 'react-toastify';

const initialState: Auth = {
  user: {
    isAuthenticated: false,
    authData: {}
  },
  status: '',
  error: false
};

export const getToken = createAsyncThunk(
  'auth/getToken',
  async (query: AuthRequest) => {
    try {
      const { token } = query;
      const response = await request.aadApiGet('/Auth/get-user-roles', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return {
        data: response.data,
        token
      };
    } catch (error) {
      console.error(error);
      toast.error('Failed to login. Please try again.');
      throw error;
    }
  }
);

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: (state) => ({ ...state, ...initialState })
  },
  extraReducers: (builder) => {
    builder.addCase(getToken.pending, (state) => {
      state.status = ApiResponseStatus.pending;
      state.error = false;
    });

    builder.addCase(getToken.fulfilled, (state, { payload }) => {
      state.user = {
        isAuthenticated: true,
        authData: payload
      };
      state.status = ApiResponseStatus.fulfilled;
      state.error = false;
    });

    builder.addCase(getToken.rejected, (state) => {
      state.user = {
        isAuthenticated: false,
        authData: {}
      };
      state.status = ApiResponseStatus.rejected;
      state.error = true;
    });
  }
});

const { actions, reducer } = userSlice;

export const { clearAuthState } = actions;

export default reducer;
