/* eslint-disable no-lone-blocks */
import { Form, Formik } from 'formik';
import {
  Button,
  DatepickerFormik,
  ModalBody,
  ModalHeader,
  Model,
  SelectInputFormik,
  TextAreaFormik,
  Typography,
  Spinner
} from 'components/atoms';
import './_leaveApplyModalStyles.scss';
import React, { useEffect, useState } from 'react';
import { getLeaveSummary, saveLeaveRequest } from 'store/Leaves';
import { RootState, store, useAppDispatch, useAppSelector } from 'store';
import moment from 'moment';
import momentBusiness from 'moment-business-days';
import { DropZone } from 'components/molecules/DropZone';
import { toast } from 'react-toastify';

export const LeaveApplyModal = () => {
  const dispatch = useAppDispatch();
  const [noOfDays, setNoOfDays] = useState(-1);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [isSessionVisible, setIsSessionVisible] = useState(false);
  const [showNoOfDays, setShowNoOfDays] = useState(false);
  const [requestSession, setRequestSession] = useState(0);
  const [sessionVisibleFlag, setSessionVisibleFlag] = useState(false);
  const [session, setSession] = useState<any>(null);
  const [inputDisabled, setInputDisabled] = useState(true);

  // for setting initial values
  const [initialValues, setInitialValues] = useState(false);

  // for setting the status for loading spinner
  const [showSpinner, setShowSpinner] = useState(false);

  // for checking whether the selected day is a weekend
  const [isWeekend, setIsWeekend] = useState(false);

  // for toggling the visibility of dropzone
  const [dropzone, setDropzone] = useState(false);

  // for enabling / disabling the "Apply" button
  const [disabled, setDisabled] = useState(false);

  const status = useAppSelector((state: RootState) => {
    return state.leaves.status ? state.leaves.status : null;
  });

  const holidays = useAppSelector((state: RootState) => {
    return state.leaves.holidays ? state.leaves.holidays : [];
  });

  momentBusiness.updateLocale('si', {
    holidays: Array.isArray(holidays)
      ? holidays?.map((item: any) =>
          moment(new Date(item.date)).format('YYYY-MM-DD')
        )
      : [],
    holidayFormat: 'YYYY-MM-DD'
  });

  // getting the remaining leave count to check whether no pay leave type needs to be shown
  const remainingLeaveCount = useAppSelector((state: RootState) => {
    return state.leaves.summary.totalRemainingLeaveCount !== undefined
      ? state.leaves.summary.totalRemainingLeaveCount
      : null;
  });

  // getting casual leave summary
  const allocatedLeaves = useAppSelector((state: RootState) => {
    return state.leaves.allocationLeaves ? state.leaves.allocationLeaves : null;
  });

  const { getState } = store;
  const { leaves } = getState();
  const { leaveTypeInfo } = leaves;

  const [casualType, setCasualType] = useState('');
  const [lieuType, setLieuType] = useState('');
  const [medicalType, setMedicalType] = useState('');
  const [annualType, setAnnualType] = useState('');
  const [nopayType, setNoPayType] = useState('');

  // formData object for appending files
  const formData = new FormData();
  // for selecting files
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  useEffect(() => {
    setDropzone(false);
  }, []);

  useEffect(() => {
    if (leaveTypeInfo) {
      leaveTypeInfo.length !== 0 &&
        leaveTypeInfo.map((info: any) => {
          switch (info.leaveType) {
            case 'Casual Leave':
              setCasualType(info.leaveTypeID);
              break;
            case 'Lieu Leave':
              setLieuType(info.leaveTypeID);
              break;
            case 'Medical Leave':
              setMedicalType(info.leaveTypeID);
              break;
            case 'Annual Leave':
              setAnnualType(info.leaveTypeID);
              break;
            case 'No Pay Leave':
              setNoPayType(info.leaveTypeID);
              break;
            default:
          }
        });
    }
  }, [leaveTypeInfo]);

  useEffect(() => {
    if (noOfDays <= 0) {
      setInputDisabled(true);
    } else {
      setInputDisabled(false);
      setShowNoOfDays(true);
    }
    if (noOfDays > 1) {
      setIsSessionVisible(false);
      setRequestSession(2);
      requestObj.leaveSession = 2;
    } else {
      if (noOfDays === 0) {
        setIsSessionVisible(false);
      } else {
        if (sessionVisibleFlag) {
          setIsSessionVisible(false);
          setSessionVisibleFlag(false);
        } else {
          setIsSessionVisible(true);
        }
      }
    }
  }, [noOfDays]);

  const formInitialValues = {
    leaveTypeId: casualType,
    startDate: '',
    endDate: '',
    leaveSession: 2,
    reason: ''
  };

  // for setting initial values
  useEffect(() => {
    if (initialValues) {
      requestObj.leaveTypeId = casualType;
      requestObj.startDate = '';
      requestObj.endDate = '';
      requestObj.leaveSession = 2;
      requestObj.reason = '';
      setNoOfDays(-1);
      setShowNoOfDays(false);
      setInitialValues(false);
      setDropzone(false);
    }
  }, [initialValues]);

  let tempDate = '';

  const validateFields = (values: any) => {
    const errors = {} as any;
    if (values.reason === '') errors.reason = 'Specify reason for leave';

    if (values.startDate === '')
      errors.startDate = 'Please select the start date';
    if (values.endDate === '') errors.endDate = 'Please select the end date';

    // checking whether the date selected is valid
    // current date values
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();

    // if (
    //   (values.startDate.$y < year ||
    //     (values.startDate.$y === year && values.startDate.$M < month) ||
    //     (values.startDate.$y === year &&
    //       values.startDate.$M === month &&
    //       values.startDate.$D < date)) &&
    //   momentBusiness([
    //     values.startDate.$y,
    //     values.startDate.$M,
    //     values.startDate.$D
    //   ]).businessDiff(momentBusiness([year, month, date])) > 2
    // )
    //   errors.startDate = 'Please select a valid start date';

    // if (
    //   (values.endDate.$y < year ||
    //     (values.endDate.$y === year && values.endDate.$M < month) ||
    //     (values.endDate.$y === year &&
    //       values.endDate.$M === month &&
    //       values.endDate.$D < date)) &&
    //   momentBusiness([
    //     values.endDate.$y,
    //     values.endDate.$M,
    //     values.endDate.$D
    //   ]).businessDiff(momentBusiness([year, month, date])) > 2
    // )
    //   errors.endDate = 'Please select a valid end date';
    // else if (values.endDate < values.startDate) {
    //   errors.endDate = 'Please select a valid end date';
    // }

    return errors;
  };

  const requestObj = {
    leaveTypeId: '',
    startDate: '',
    endDate: '',
    leaveSession: 0,
    reason: ''
  };
  const submitRequest = async () => {
    formData.append('leaveTypeId', requestObj.leaveTypeId);
    formData.append('leaveSession', requestObj.leaveSession.toString());
    formData.append('startDate', requestObj.startDate);
    formData.append('endDate', requestObj.endDate);
    formData.append('reason', requestObj.reason);
    selectedFiles.map((file: any, index: number) =>
      formData.append('files', file, file.name)
    );

    setShowSpinner(true);
    await dispatch(saveLeaveRequest(formData));

    if (status === 'fulfilled') {
      await dispatch(getLeaveSummary());
      setShowSpinner(false);
      setShowModal(false);
    } else if (status === 'rejected') {
      toast.error('Could not place the leave request!');
      setShowSpinner(false);
      setShowModal(false);
    }
  };

  const modalOnSubmit = (values: any) => {
    requestObj.leaveTypeId = values.leaveTypeId;

    tempDate = moment
      .utc(values.startDate.toString())
      .utcOffset('+05:30')
      .format();
    tempDate = tempDate.slice(0, -6);
    tempDate += '.000Z';
    requestObj.startDate = tempDate;

    tempDate = moment
      .utc(values.endDate.toString())
      .utcOffset('+05:30')
      .format();
    tempDate = tempDate.slice(0, -6);
    tempDate += '.000Z';
    requestObj.endDate = tempDate;

    requestObj.leaveSession = values.leaveSession;

    requestObj.reason = values.reason;

    submitRequest();
  };

  const handleOpen = () => {
    setInitialValues(true);
    setSelectedFiles([]);
    setDisabled(false);
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setNoOfDays(0);
  };

  // for setting number of days
  const calculateNoOfDays = (startDate: any, endDate: any) => {
    if (endDate < startDate) {
      setNoOfDays(0);
    } else {
      let noOfDaysCount =
        momentBusiness([endDate.$y, endDate.$M, endDate.$D]).businessDiff(
          moment([startDate.$y, startDate.$M, startDate.$D])
        ) + 1;

      setNoOfDays(noOfDaysCount);

      if (
        moment([startDate.$y, startDate.$M, startDate.$D]).day() < 6 &&
        (moment([endDate.$y, endDate.$M, endDate.$D]).day() === 6 ||
          moment([endDate.$y, endDate.$M, endDate.$D]).day() === 0)
      )
        setNoOfDays(
          moment([endDate.$y, endDate.$M, endDate.$D]).businessDiff(
            moment([startDate.$y, startDate.$M, startDate.$D])
          )
        );

      // setting number of days to 0 when the user selects two consecutive weekend dates
      if (
        (moment([startDate.$y, startDate.$M, startDate.$D]).day() === 6 ||
          moment([startDate.$y, startDate.$M, startDate.$D]).day() === 0) &&
        (moment([endDate.$y, endDate.$M, endDate.$D]).day() === 6 ||
          moment([endDate.$y, endDate.$M, endDate.$D]).day() === 0) &&
        moment([endDate.$y, endDate.$M, endDate.$D]).diff(
          moment([startDate.$y, startDate.$M, startDate.$D]),
          'days'
        ) <= 1
      ) {
        setNoOfDays(0);
        setIsWeekend(true);
      } else {
        setIsWeekend(false);
      }
    }
  };

  const setLeaveType = (setFieldValue: any, value: any) => {
    // toggling the dropzone visibility according to leave type
    if (value === medicalType) {
      setDropzone(true);
      setDisabled(true);
    } else {
      setDropzone(false);
      setDisabled(false);
    }
    // enabling session selection for casual and lieu leave types
    if (
      ((casualType === value || lieuType === value) && noOfDays === 1) ||
      ((casualType === value || lieuType === value) && noOfDays === 0.5)
    ) {
      if (session === 1 || session === 0) {
        setNoOfDays(0.5);
      }
      setIsSessionVisible(true);
    } else if (
      (casualType !== value || lieuType !== value) &&
      noOfDays === 0.5
    ) {
      setSessionVisibleFlag(true);
      setNoOfDays(1);
      setLeaveSession(setFieldValue, 2);
    } else {
      setShowNoOfDays(true);
      setIsSessionVisible(false);
      setRequestSession(2);
      requestObj.leaveSession = 2;
    }
    setFieldValue('leaveTypeId', value);
  };

  const setLeaveSession = (setFieldValue: any, value: any) => {
    // checking whether selected dates are on weekend of same week
    if (isWeekend) {
      setNoOfDays(0);
    } else {
      if (parseInt(value) === 0 || parseInt(value) === 1) {
        setSession(parseInt(value));
        setNoOfDays(0.5);
        setShowNoOfDays(true);
      } else {
        setSession(parseInt(value));
        setNoOfDays(1);
        setShowNoOfDays(true);
      }
    }

    setFieldValue('leaveSession', parseInt(value));
  };
  const handleDisableDate = (date: any) => {
    const day = date.day();
    let isDisabled = false;
    if (holidays.length > 0) {
      isDisabled =
        holidays.some(
          (item: any) =>
            moment(new Date(item.date)).format('YYYY-MM-DD') ===
            moment(new Date(date.$d)).format('YYYY-MM-DD')
        ) ||
        day === 0 ||
        day === 6;
    }

    return isDisabled;
  };

  return (
    <>
      <Button
        type="success"
        size="base"
        icon="left"
        iconSrc="/icons/send.svg"
        onClick={handleOpen}
      >
        Apply a Leave
      </Button>

      <Model modalSize="lg" showModal={showModal} onClose={handleClose}>
        <Formik
          initialValues={formInitialValues}
          // onSubmit={(values) => {
          //   if (
          //     values.leaveTypeId !== medicalType ||
          //     (values.leaveTypeId === medicalType && selectedFiles.length > 0)
          //   ) {
          //     setDisabled(false);
          //     modalOnSubmit(values);
          //   } else setDisabled(true);
          // }}
          onSubmit={modalOnSubmit}
          validate={validateFields}
          validateOnChange
        >
          {({ values, setFieldValue }: any) => (
            <Form>
              <ModalHeader
                headerTitle="Leave Application Form"
                onClose={handleClose}
              />
              <ModalBody>
                <div className="leave-dates-pickers">
                  <div className="date-picker-start">
                    <DatepickerFormik
                      labelText="Leave Start:"
                      name="startDate"
                      required={true}
                      defaultDate=""
                      handleDisableDate={handleDisableDate}
                      handleChange={(event: any) => {
                        setFieldValue('startDate', event);
                        calculateNoOfDays(event, values.endDate);
                      }}
                    />
                  </div>
                  <div className="date-picker-end">
                    <DatepickerFormik
                      labelText="Leave End:"
                      name="endDate"
                      disabled={values.startDate === ''}
                      minDate={values.startDate}
                      required={true}
                      defaultDate=""
                      handleDisableDate={handleDisableDate}
                      handleChange={(value: any) => {
                        setFieldValue('endDate', value);
                        calculateNoOfDays(values.startDate, value);
                      }}
                    />
                  </div>
                </div>

                <div className="leave-select">
                  <SelectInputFormik
                    name="leaveTypeId"
                    label="Select leave type:"
                    error={false}
                    required={true}
                    handleChange={(event: any) => {
                      setLeaveType(setFieldValue, event.target.value);
                    }}
                    disabled={inputDisabled}
                  >
                    <option value="">Select Leave Type</option>
                    {leaveTypeInfo ? (
                      leaveTypeInfo.length !== 0 &&
                      leaveTypeInfo.map((info: any) => {
                        const leaveDetails = allocatedLeaves?.find(
                          (leave) => leave.leaveType === info.leaveType
                        );
                        if (!leaveDetails) {
                          return null;
                        }
                        const { remainingCount } = leaveDetails;

                        //conditions
                        if (
                          info.leaveType === 'Casual Leave' &&
                          remainingCount === 0
                        )
                          return <></>;
                        else if (
                          info.leaveType === 'Annual Leave' &&
                          remainingCount === 0
                        )
                          return <></>;
                        else if (
                          info.leaveType === 'Lieu Leave' &&
                          remainingCount === 0
                        )
                          return <></>;
                        else if (
                          info.leaveType === 'Nopay Leave' &&
                          remainingLeaveCount > 0
                        )
                          return <></>;
                        else
                          return (
                            <option value={info.leaveTypeID}>
                              {info.leaveType}
                            </option>
                          );
                      })
                    ) : (
                      <option value="">Select Leave Type</option>
                    )}
                  </SelectInputFormik>
                </div>

                {isSessionVisible && (
                  <div className="leave-select">
                    <SelectInputFormik
                      name="leaveSession"
                      label="Select leave session:"
                      error={false}
                      required={true}
                      handleChange={(event: any) =>
                        setLeaveSession(setFieldValue, event.target.value)
                      }
                      disabled={inputDisabled}
                    >
                      <option value={0}>Morning Session</option>
                      <option value={1}>Evening Session</option>
                      <option value={2}>Full Day</option>
                    </SelectInputFormik>
                  </div>
                )}

                {showNoOfDays && (
                  <Typography
                    type="small_bold"
                    text={`Number of days selected: ${noOfDays.toString()}`}
                  />
                )}
                <div className="leave-desc">
                  <TextAreaFormik
                    name="reason"
                    id="sample-textarea"
                    rows={5}
                    label="Leave Description:"
                    placeholder="Leave Description"
                    required={true}
                    handleChange={(event: any) =>
                      setFieldValue('reason', event.target.value)
                    }
                    disabled={inputDisabled}
                  />
                </div>

                {dropzone && <DropZone setSelectedFiles={setSelectedFiles} />}

                <div className="modal_footer_buttons">
                  {showSpinner ? (
                    <Button
                      className="modal-option-btn"
                      type="success"
                      disabled={true}
                      size="base"
                      htmlType="submit"
                      onClick={() => {}}
                    >
                      <Spinner showSpinner={showSpinner} type="btn_spinner" />
                    </Button>
                  ) : (
                    <Button
                      className="modal-option-btn"
                      type="success"
                      size="base"
                      htmlType="submit"
                      onClick={() => {}}
                      disabled={disabled && selectedFiles.length === 0}
                    >
                      Apply
                    </Button>
                  )}
                  <Button
                    className="modal-option-btn"
                    type="secondary"
                    size="base"
                    onClick={() => {
                      setShowModal(false);
                      setNoOfDays(0);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalBody>
            </Form>
          )}
        </Formik>
      </Model>
    </>
  );
};
