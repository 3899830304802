import React, { useState } from 'react';
import { Icon } from '../../atoms/Icon';
import { Avatar } from '../../atoms/Avatar';
import { Divider } from '../../atoms/Divider';
import './_header.scss';
import { HeaderSection, Typography } from 'components/atoms';
import { store } from 'store';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import jwt from 'jwt-decode';

interface Props {
  portalTitle?: string;
}

export const Header = (props: Props) => {
  const { portalTitle } = props;
  const { getState } = store;
  const { auth } = getState();
  const { user } = auth;
  const { authData } = user;

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let token = { name: '' };

  if (authData !== undefined) {
    token = jwt(authData.token);
  }

  // function for logout
  const logout = () => {
    navigate('/logout');
  };

  const child1 = (
    <div className="color logo-container">
      <img className="logo" src="/icons/aventude_logo_white.svg" />
      <Typography
        type="logo_bold"
        text={portalTitle || 'Leave'}
        className="logo-text"
      />
    </div>
  );
  const child2 = (
    <div className="spacing color">
      <div>
        <Icon type="primary" src="/icons/question.svg" />
        <Typography type="small_bold" text="Help & Support" />
      </div>
      <Divider orientation="vr" size="lg" />
      <Avatar
        name={`${authData !== undefined ? token.name : ''}`}
        size="sm"
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
  return <HeaderSection child1={child1} child2={child2} />;
};
