export const aadConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    authority: process.env.REACT_APP_AUTHORITY || '',
    redirectUri: process.env.REACT_APP_REDIRECT_URL || ''
  }
};

export const loginRequest = {
  scopes: ['api://d7cbcd21-3b2a-4899-8ad7-8eaab13fe67d/employeeportalaccess']
};
