import React from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import './index.scss';

interface IProps {
  name: string;
  id?: string;
  label: string;
  placeholder?: string;
  inputWrapperClass?: string;
  inputClass?: string;
  required?: boolean;
  disabled?: boolean;
}

export const Input = ({
  name,
  id,
  label,
  placeholder,
  inputWrapperClass,
  inputClass,
  required,
  disabled
}: IProps) => {
  const [field, meta] = useField(name);

  return (
    <div className={`input-wrapper ${inputWrapperClass}`}>
      <label htmlFor={id || name} className="input-label">
        {label}
        {required ? <span className="input-required">*</span> : null}
      </label>
      <Field
        type="text"
        className={`input-box ${inputClass} ${
          meta.touched && meta.error ? 'input-error' : ''
        }`}
        name={name}
        id={id || name}
        placeholder={placeholder}
        disabled={disabled}
      />
      <ErrorMessage name={name} component="div" className="error" />
    </div>
  );
};

interface IPropsPro extends IProps {
  value: string;
  onChange: (value: string) => void;
}

export const InputPro = ({
  name,
  id,
  label,
  placeholder,
  inputWrapperClass,
  inputClass,
  required,
  disabled,
  value,
  onChange
}: IPropsPro) => {
  return (
    <div className={`input-wrapper ${inputWrapperClass}`}>
      {label && (
        <label htmlFor={id || name} className="input-label">
          {label}
          {required ? <span className="input-required">*</span> : null}
        </label>
      )}
      <input
        type="text"
        className={`input-box ${inputClass}`}
        name={name}
        id={id || name}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
