import React from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import './index.scss';

interface IProps {
  name: string;
  id?: string;
  label: string;
  placeholder?: string;
  inputWrapperClass?: string;
  inputClass?: string;
  required?: boolean;
}

export const TextAreaPro = ({
  name,
  id,
  label,
  placeholder,
  inputWrapperClass,
  inputClass,
  required
}: IProps) => {
  const [field, meta] = useField(name);

  return (
    <div className={`input-wrapper ${inputWrapperClass}`}>
      <label htmlFor={id || name} className="input-label">
        {label}
        {required ? <span className="input-required">*</span> : null}
      </label>
      <Field
        as="textarea"
        className={`input-textarea-box ${inputClass} ${
          meta.touched && meta.error ? 'input-error' : ''
        }`}
        name={name}
        id={id || name}
        placeholder={placeholder}
      />
      <ErrorMessage name={name} component="div" className="error" />
    </div>
  );
};
