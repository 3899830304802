import React from 'react';
import './_divider.scss';

interface Props {
  size: 'sm' | 'lg';
  orientation: 'hr' | 'vr';
  customClass?: string;
}

export const Divider = ({ size, orientation, customClass }: Props) => {
  return <div className={`divider ${orientation} ${size} ${customClass}`} />;
};
