export enum ApiResponseStatus {
  idle = 'idle',
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected'
}

export enum LeaveTypeId {
  casual = 'DD2C7E62-A8D1-41DA-A3D0-BF65110AE424',
  lieu = '4ED0A89E-6E3F-4D24-A233-FA60A933FF44',
  medical = '16FA9E94-6ECC-4D63-92BE-754470DE6F22',
  annual = 'E0A25095-B069-4BAB-B5C4-D0FC493ADE85'
}

export enum UserRoles {
  ADMIN = 'admin',
  MANAGER = 'manager',
  TEAM_LEAD = 'teamLead',
  STAFF = 'staff'
}

export enum UserRoleNames {
  admin = 'Admin',
  manager = 'Manager',
  teamLead = 'Team Lead',
  staff = 'Staff'
}

export enum MaritalStatus {
  married = 'Married',
  unmarried = 'Unmarried'
}

export enum GenderTypes {
  male = 'Male',
  female = 'Female'
}
