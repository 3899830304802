import { Typography } from 'components/atoms';
import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import './index.scss';

const menuItemStyles = {};

export const AdminSidebar = () => {
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="sidebar-wrapper">
      <Sidebar
        collapsed={collapsed}
        backgroundColor="#ffffff"
        style={{ border: 'none' }}
        collapsedWidth="110px"
      >
        <div className="sidebar-main-wrapper">
          <div className="sidebar-menus">
            <div className="sidebar-heading-wrapper">
              <Typography type="base_regular" text="Manager" />
            </div>

            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                icon={<img src="/icons/dashboard.svg" />}
                component={<Link to="/admin/employee-summary-dashboard" />}
                active={
                  location.pathname === '/admin/employee-summary-dashboard'
                }
              >
                Dashboard
              </MenuItem>
              <MenuItem
                icon={<img src="/icons/employee.svg" />}
                component={<Link to="/admin/add-employee" />}
                active={location.pathname === '/admin/add-employee'}
              >
                Add Employee
              </MenuItem>
              <MenuItem
                icon={<img src="/icons/list-check.svg" />}
                component={<Link to="/admin/leave-summary" />}
                active={location.pathname === '/admin/leave-summary'}
              >
                Leave Summary
              </MenuItem>
              <MenuItem
                icon={<img src="/icons/summary_list.svg" />}
                component={<Link to="/admin" />}
                active={location.pathname === '/admin'}
              >
                Employee Summary
              </MenuItem>
            </Menu>

            <div className="sidebar-heading-wrapper">
              <Typography type="base_regular" text="Other" />
            </div>

            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                icon={<img src="/icons/log.svg" />}
                active={false}
                disabled
              >
                Activity Log
              </MenuItem>
            </Menu>
          </div>

          <div
            className={
              collapsed ? 'collapsed sidebar-footer' : 'sidebar-footer'
            }
          >
            <div />
            <button
              className="collapse-btn"
              type="button"
              onClick={() => setCollapsed(!collapsed)}
            >
              <img src="/icons/collapse.svg" alt="" />
              {!collapsed ? <span>Collapse</span> : null}
            </button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};
