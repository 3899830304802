import { configureStore } from '@reduxjs/toolkit';
import userSlice from './Auth';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import leaveSlice from './Leaves';
import adminSlice from './Admin';
import masterDataSlice from './MasterData';

import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';

const persistConfig = {
  key: '_Authentication',
  keyPrefix: 'Lms',
  version: 1,
  storage
};

const persistedReducer = persistReducer(persistConfig, userSlice);
export const store = configureStore({
  reducer: {
    auth: persistedReducer,
    leaves: leaveSlice,
    admin: adminSlice,
    masterData: masterDataSlice
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    });
  }
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
