import { useField } from 'formik';
import './_textareaInput.scss';

interface TextAreaInputType {
  id: string;
  name?: string;
  label?: string;
  helperText?: string;
  placeholder?: string;
  rows: number;
}

interface TextAreaInputFormikType {
  id: string;
  name: string;
  label?: string;
  helperText?: string;
  placeholder?: string;
  rows: number;
  required?: boolean;
  handleChange?: (option: any) => void;
  disabled?: boolean;
}

export const TextArea = (props: TextAreaInputType) => {
  const { name, id, placeholder, rows, label, helperText } = props;
  return (
    <>
      {label ? <label className="input-label">{label}</label> : ''}
      <textarea
        name={name}
        id={id}
        className="input-textarea"
        rows={rows}
        placeholder={placeholder}
      ></textarea>
      {helperText ? <p className="input-helper-label">{helperText}</p> : ''}
    </>
  );
};

export const TextAreaFormik = (props: TextAreaInputFormikType) => {
  const {
    name,
    id,
    placeholder,
    rows,
    label,
    helperText,
    required,
    handleChange,
    disabled
  } = props;

  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  return (
    <>
      {label ? <label className="input-label">{label}</label> : ''}
      <textarea
        id={id}
        className="input-textarea"
        rows={rows}
        placeholder={placeholder}
        {...field}
        onChange={handleChange}
        disabled={disabled}
      ></textarea>
      {helperText ? <p className="input-helper-label">{helperText}</p> : ''}
      {!meta.touched && !meta.error && helperText && (
        <div className="input-helper-label">{helperText}</div>
      )}

      {required && meta.touched && meta.error && (
        <div className="input-error-message">{meta.error}</div>
      )}
    </>
  );
};
