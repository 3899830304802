import React from 'react';
import { AddEmployeeForm, AdminHeader, AdminSidebar } from 'components';

export const AddEmployee = () => {
  return (
    <>
      <AdminHeader />
      <div className="dashboard-wrapper">
        <AdminSidebar />
        <AddEmployeeForm />
      </div>
    </>
  );
};
