import classNames from 'classnames';
import React from 'react';
import './index.scss';

type Record = {
  [key: string]: any;
};

type Option = Record;
interface Props {
  text: string;
  rounded?: boolean;
  dot?: boolean;
  icon?: string;
  status: string;
  onRemove?: (option: Option) => void | undefined;
  badgeClasses?: string;
}

export const Chip = ({
  text,
  rounded,
  dot,
  icon,
  status,
  onRemove,
  badgeClasses
}: Props) => {
  const getColorsByState = (status: string) => {
    switch (status) {
      case 'Internship':
        return 'blue';
      case 'Confirmed':
        return 'green';
      case 'Resigned':
        return 'grey';
      case 'Terminated':
        return 'red';
      case 'Probation':
        return 'yellow';
      default:
        return 'grey';
    }
  };

  return (
    <span
      className={classNames(
        `badge ${onRemove ? 'badge-removable' : ''} ${
          rounded ? 'badge-rounded' : ''
        } ${getColorsByState(status)}`,
        badgeClasses
      )}
    >
      {icon && <i className={classNames(icon, 'badge-icon-content')} />}
      {dot && <span className="dot" />} {text}{' '}
      {onRemove && (
        <i
          className="ri-close-line badge-remove-icon"
          onClick={onRemove}
          role="presentation"
        />
      )}
    </span>
  );
};

Chip.defaultProps = {
  rounded: undefined,
  dot: undefined,
  onRemove: undefined
};
