import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiResponseStatus } from 'models/enums';
import { LeaveInfo } from 'models/models';
import moment from 'moment';
import { request } from 'services/apis';
import { toast } from 'react-toastify';

const initialState: LeaveInfo = {
  summary: {},
  summaryDuplicate: {},
  // for filter refactoring
  filteredSummary: {},
  // for paginate refactoring
  paginateSummary: {},
  request: {},
  requests: [],
  holidays: [],
  status: '',
  masterDataStatus: '',
  updateLeaveStatusState: '',
  validateLeave: {},
  error: false,
  // for pagination
  activePage: 1,
  rowsPerPage: 20,
  totalPages: 1,
  totalLeaves: 0,
  // for sorting
  orderAsc: 1,
  orderBy: '',
  leaveTypeInfo: [],
  // for new search and filter
  filterType: '',
  searchKeyWord: '',
  // for date range filtering
  startDate: '',
  endDate: '',
  allocationLeaves: []
};

export const getLeaveTypes = createAsyncThunk(
  'leaves/getLeaveTypes',
  async () => {
    try {
      const response = await request.get('/Leave/leave-types');
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getLeaveSummary = createAsyncThunk(
  'leaves/getLeaveSummary',
  async () => {
    try {
      const response = await request.get('/Leave/leave-summary');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const getPublicHolidays = createAsyncThunk(
  'leaves/getPublicHolidays',
  async () => {
    try {
      const response = await request.get('/Holiday/public-holidays');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

interface leaveRequestProp {
  leaveTypeId: string;
  startDate: string;
  endDate: string;
  leaveSession: number;
  reason: string;
}
export const saveLeaveRequest = createAsyncThunk(
  'leaves/saveLeaveRequest',
  async (leaveRequest: FormData) => {
    try {
      const response: any = await request.post('/Leave/save', leaveRequest);

      toast.success(response.data.message);
      return response.data;
    } catch (error: any) {
      if (error.response.data.status) {
        if (error.response.data.attachments === null)
          toast.error('Medical leaves require at least one attachment');
        else toast.error(error.response.data.status);
      }
      if (error.response.data.message === undefined)
        toast.error(error.response.data);
      else toast.error(error.response.data.message);

      if (error.response.status === 401) toast.error('You are not signed in');
    }
  }
);

// delete request
export const deleteLeaveRequest = createAsyncThunk(
  'leaves/deleteLeaveRequest',
  async (id: any) => {
    try {
      const response: any = await request.delete(`/Leave/delete-request/${id}`);
      if (response.data === true) {
        toast.success('Leave request deleted successfully!');
      }
      return response.data;
    } catch (error) {
      toast.error('Could not delete the leave request!');
      console.error(error);
    }
  }
);

// update request - user
export const updateLeaveRequest = createAsyncThunk(
  'leaves/updateLeaveRequest',
  async (data: any, thunkApi) => {
    const { id, formData } = data;
    try {
      const response: any = await request.put(
        `/Leave/update-request/${id}`,
        formData
      );
      toast.success(response.data.message);
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error: any) {
      if (error.response.data.message) toast.error(error.response.data.message);

      if (error.response.data.errors.Files) {
        error.response.data.errors.Files.map((file: any) => toast.error(file));
      } else if (error.response.data.message === undefined)
        toast.error(error.response.data);
      else toast.error(error.response.data.message);

      if (error.response.status === 401) toast.error('You are not signed in');
      console.log(error);
    }
  }
);

// update status of leave request - admin
export const updateLeaveRequestStatus = createAsyncThunk(
  'leaves/updateLeaveRequestStatus',
  async (requestObj: any, thunkApi) => {
    try {
      const response: any = await request.put(
        `/leave/update-status`,
        requestObj
      );
      toast.success(response.data.message);

      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error: any) {
      if (error.response.data.message === undefined)
        toast.error(error.response.data);
      else toast.error(error.response.data.message);

      if (error.response.status === 401) toast.error('You are not signed in');
    }
  }
);

export const validateLeave = createAsyncThunk(
  'leaves/validateLeave',
  async (requestObj: any, thunkApi) => {
    try {
      const response: any = await request.get(
        `/leave/latest-request/${requestObj.leaveId}/${requestObj.updated}`
      );
      toast.success(response.data.message);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error: any) {
      if (error.response.data.message === undefined)
        toast.error(error.response.data);
      else toast.error(error.response.data.message);

      if (error.response.status === 401) toast.error('You are not signed in');
    }
  }
);

const leaveSlice = createSlice({
  name: 'leaves',
  initialState,
  reducers: {
    clearValidateResponse: (state) => ({
      ...state,
      validateLeave: {},
      updateLeaveStatusState: ''
    }),
    // Pagination
    paginateLeaves: (state, { payload }) => {
      state.rowsPerPage = payload;
      state.activePage = 1;
      state.totalPages = state.paginateSummary.leaveSummary
        ? Math.ceil(
            state.paginateSummary.leaveSummary.length / state.rowsPerPage
          )
        : 1;
      if (state.totalPages === 0) {
        state.totalPages = 1;
      }
      state.summary = {
        ...state.summary,
        leaveSummary: state.paginateSummary.leaveSummary
          ? state.paginateSummary.leaveSummary.slice(
              (state.activePage - 1) * state.rowsPerPage,
              state.activePage * state.rowsPerPage
            )
          : []
      };
    },
    navigateLeaves: (state, { payload }) => {
      state.activePage = payload;
      state.summary = {
        ...state.summary,
        leaveSummary: state.paginateSummary.leaveSummary.slice(
          (state.activePage - 1) * state.rowsPerPage,
          state.activePage * state.rowsPerPage
        )
      };
    },
    // Pagination

    // Newest filter method
    filterLeaves: (state, { payload }) => {
      state.filterType =
        payload.filterByType !== null ? payload.filterByType : 'All';

      state.startDate =
        payload.startDate !== null
          ? payload.startDate
          : moment(new Date(0)).format('YYYY-MM-DD');
      state.endDate =
        payload.endDate !== null
          ? payload.endDate
          : moment(new Date(8640000000000000)).format('YYYY-MM-DD');

      state.filteredSummary = {
        ...state.filteredSummary,
        leaveSummary: state.summaryDuplicate.leaveSummary
          .filter((item: any) =>
            state.filterType !== 'All'
              ? item.leaveType === state.filterType
              : state.summaryDuplicate.leaveSummary
          )
          .filter((item: any) =>
            moment(item.startDate).isBetween(
              moment(state.startDate),
              moment(state.endDate),
              undefined,
              '[]'
            )
          )
      };

      // setting the total number of pages as according to the result of filtering by type
      state.totalPages = state.filteredSummary.leaveSummary
        ? Math.ceil(
            state.filteredSummary.leaveSummary.length / state.rowsPerPage
          )
        : 1;

      // setting the total pages to 1, when the filtered list is empty
      if (state.totalPages === 0) state.totalPages = 1;

      // extra conditions (i) search => filter
      if (state.searchKeyWord === '') {
        state.summary = {
          ...state.summary,
          leaveSummary: state.filteredSummary.leaveSummary
        };
      } else {
        state.summary = {
          ...state.summary,
          leaveSummary: state.filteredSummary.leaveSummary.filter((item: any) =>
            item.reason
              .toLowerCase()
              .includes(state.searchKeyWord.toLowerCase())
          )
        };

        // setting the total pages in case a search keyword is entered
        state.totalPages = state.summary.leaveSummary
          ? Math.ceil(state.summary.leaveSummary.length / state.rowsPerPage)
          : 1;
      }

      // setting list to be used for pagination
      state.paginateSummary = {
        ...state.paginateSummary,
        leaveSummary: state.summary.leaveSummary
      };

      if (state.rowsPerPage !== null) {
        state.summary = {
          ...state.summary,
          leaveSummary: state.summary.leaveSummary.slice(
            (state.activePage - 1) * state.rowsPerPage,
            state.activePage * state.rowsPerPage
          )
        };
      }
    },
    // end of filter method

    // start of search method
    searchLeaves: (state, { payload }) => {
      state.searchKeyWord = payload !== null ? payload : '';
      state.summary = {
        ...state.summary,
        leaveSummary:
          state.searchKeyWord !== ''
            ? state.filteredSummary.leaveSummary.filter((item: any) =>
                item.reason
                  .toLowerCase()
                  .includes(state.searchKeyWord.toLowerCase())
              )
            : state.filteredSummary.leaveSummary
      };

      // setting list to be used for pagination
      state.paginateSummary = {
        ...state.paginateSummary,
        leaveSummary: state.summary.leaveSummary
      };

      // setting the total number of pages as according to the result of filtering by type
      state.totalPages = state.filteredSummary.leaveSummary
        ? Math.ceil(
            state.filteredSummary.leaveSummary.length / state.rowsPerPage
          )
        : 1;

      // setting the total number of pages when the list
      if (state.filterType !== null && state.searchKeyWord !== null) {
        state.totalPages = state.paginateSummary.leaveSummary
          ? Math.ceil(
              state.paginateSummary.leaveSummary.length / state.rowsPerPage
            )
          : 1;
      }

      // setting the total number of pages to 1 in case it was set to zero
      if (state.totalPages === 0) state.totalPages = 1;

      if (state.rowsPerPage !== null) {
        state.summary = {
          ...state.summary,
          leaveSummary: state.summary.leaveSummary.slice(
            (state.activePage - 1) * state.rowsPerPage,
            state.activePage * state.rowsPerPage
          )
        };
      }
    },
    // end of search method

    sortLeaves: (state, { payload }) => {
      state.orderAsc *= -1;

      switch (payload) {
        case 'reason':
        case 'startDate':
        case 'endDate':
        case 'leaveStatus':
        case 'adminComment':
        case 'leaveSession':
        case 'noOfdays':
          state.summary = {
            ...state.summary,
            leaveSummary: state.summary.leaveSummary
              ? state.summary.leaveSummary.sort(
                  (a: any, b: any) =>
                    a[payload]
                      .toString()
                      .localeCompare(b[payload].toString(), 'en', {
                        numeric: true
                      }) * state.orderAsc
                )
              : []
          };
          break;
        default:
          break;
      }
    },
    resetFilter: (state) => {
      state.filterType = 'All';
      state.startDate = '';
      state.endDate = '';
      // set the filteredSummary list to hold all leaves
      state.filteredSummary = {
        ...state.filteredSummary,
        leaveSummary: state.summaryDuplicate.leaveSummary
      };

      // do the search operation on unfiltered leave list, if a search keyword is given
      state.summary = {
        ...state.summary,
        leaveSummary:
          state.searchKeyWord !== ''
            ? state.summary.leaveSummary.filter((item: any) =>
                item.reason
                  .toLowerCase()
                  .includes(state.searchKeyWord.toLowerCase())
              )
            : state.filteredSummary.leaveSummary
      };

      state.paginateSummary = {
        ...state.paginateSummary,
        leaveSummary: state.summary.leaveSummary
      };

      if (state.rowsPerPage) {
        state.summary = {
          ...state.summary,
          leaveSummary: state.filteredSummary.leaveSummary
            .filter((item: any) =>
              item.reason
                .toLowerCase()
                .includes(state.searchKeyWord.toLowerCase())
            )
            .slice(
              (state.activePage - 1) * state.rowsPerPage,
              state.activePage * state.rowsPerPage
            )
        };

        state.totalPages = state.paginateSummary.leaveSummary
          ? Math.ceil(
              state.paginateSummary.leaveSummary.length / state.rowsPerPage
            )
          : 1;
      } else {
        state.summary = {
          ...state.summary,
          leaveSummary: state.filteredSummary.leaveSummary.filter((item: any) =>
            item.reason
              .toLowerCase()
              .includes(state.searchKeyWord.toLowerCase())
          )
        };
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLeaveTypes.pending, (state) => {
      state.masterDataStatus = ApiResponseStatus.pending;
      state.status = ApiResponseStatus.pending;
      state.error = false;
    });
    builder.addCase(getLeaveTypes.fulfilled, (state, { payload }) => {
      state.leaveTypeInfo = payload;
      state.masterDataStatus = ApiResponseStatus.fulfilled;
      state.status = ApiResponseStatus.fulfilled;
      state.error = false;
    });
    builder.addCase(getLeaveTypes.rejected, (state) => {
      state.masterDataStatus = ApiResponseStatus.rejected;
      state.status = ApiResponseStatus.rejected;
      state.error = true;
    });

    builder.addCase(getPublicHolidays.pending, (state) => {
      state.masterDataStatus = ApiResponseStatus.pending;
      state.status = ApiResponseStatus.pending;
      state.error = false;
    });
    builder.addCase(getPublicHolidays.fulfilled, (state, { payload }) => {
      state.holidays = payload;
      state.masterDataStatus = ApiResponseStatus.fulfilled;
      state.status = ApiResponseStatus.fulfilled;
      state.error = false;
    });
    builder.addCase(getPublicHolidays.rejected, (state) => {
      state.masterDataStatus = ApiResponseStatus.rejected;
      state.status = ApiResponseStatus.rejected;
      state.error = true;
    });

    builder.addCase(getLeaveSummary.pending, (state) => {
      state.masterDataStatus = ApiResponseStatus.pending;
      state.status = ApiResponseStatus.pending;
      state.error = false;
    });
    builder.addCase(getLeaveSummary.fulfilled, (state, { payload }: any) => {
      state.summary = payload;
      state.summaryDuplicate = payload;
      state.filteredSummary = payload;
      state.allocationLeaves = payload.allocationLeaves;
      state.activePage = 1;
      state.rowsPerPage = 20;
      state.totalPages = 1;
      state.totalLeaves = 0;
      state.paginateSummary = payload;
      state.totalLeaves = state.summary.leaveSummary.length;
      state.masterDataStatus = ApiResponseStatus.fulfilled;
      state.status = ApiResponseStatus.fulfilled;
      state.error = false;
    });
    builder.addCase(getLeaveSummary.rejected, (state) => {
      state.masterDataStatus = ApiResponseStatus.rejected;
      state.status = ApiResponseStatus.rejected;
      state.error = true;
    });
    builder.addCase(saveLeaveRequest.pending, (state) => {
      state.status = ApiResponseStatus.pending;
      state.error = false;
    });
    builder.addCase(saveLeaveRequest.fulfilled, (state, payload) => {
      state.requests = payload;
      state.status = ApiResponseStatus.fulfilled;
      state.error = false;
    });
    builder.addCase(saveLeaveRequest.rejected, (state) => {
      state.status = ApiResponseStatus.rejected;
      state.error = true;
    });
    builder.addCase(updateLeaveRequest.pending, (state) => {
      state.status = ApiResponseStatus.pending;
      state.error = false;
    });
    builder.addCase(updateLeaveRequest.fulfilled, (state, payload) => {
      state.requests = payload;
      state.status = ApiResponseStatus.fulfilled;
      state.error = false;
    });
    builder.addCase(updateLeaveRequest.rejected, (state) => {
      state.status = ApiResponseStatus.rejected;
      state.error = true;
    });
    builder.addCase(deleteLeaveRequest.pending, (state) => {
      state.status = ApiResponseStatus.pending;
      state.error = false;
    });
    builder.addCase(deleteLeaveRequest.fulfilled, (state, payload) => {
      state.requests = payload;
      state.status = ApiResponseStatus.fulfilled;
      state.error = false;
    });
    builder.addCase(deleteLeaveRequest.rejected, (state) => {
      state.status = ApiResponseStatus.rejected;
      state.error = true;
    });

    // update leave request status
    builder.addCase(updateLeaveRequestStatus.pending, (state) => {
      state.status = ApiResponseStatus.pending;
      state.error = false;
    });
    builder.addCase(updateLeaveRequestStatus.fulfilled, (state, payload) => {
      state.status = ApiResponseStatus.fulfilled;
      state.updateLeaveStatusState = ApiResponseStatus.fulfilled;
      state.error = false;
    });
    builder.addCase(updateLeaveRequestStatus.rejected, (state) => {
      state.status = ApiResponseStatus.rejected;
      state.error = true;
    });

    builder.addCase(validateLeave.pending, (state) => {
      state.status = ApiResponseStatus.pending;
      state.error = false;
    });
    builder.addCase(validateLeave.fulfilled, (state, payload) => {
      state.status = ApiResponseStatus.fulfilled;
      state.validateLeave = payload.payload;
      state.error = false;
    });
    builder.addCase(validateLeave.rejected, (state) => {
      state.status = ApiResponseStatus.rejected;
      state.error = true;
    });
  }
});

const { actions, reducer } = leaveSlice;

export const {
  paginateLeaves,
  navigateLeaves,
  sortLeaves,
  resetFilter,
  filterLeaves,
  searchLeaves,
  clearValidateResponse
} = actions;

export default reducer;
