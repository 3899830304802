import React from 'react';
import { Icon, Typography } from 'components/atoms';
import './_pagination.scss';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { navigateLeaves, paginateLeaves } from 'store/Leaves';

export const Pagination = () => {
  const dispatch = useAppDispatch();
  const activePage = useAppSelector((state: RootState) => {
    return state.leaves.activePage;
  });
  const totalPages = useAppSelector((state: RootState) => {
    return state.leaves.totalPages;
  });
  const rowsPerPage = useAppSelector((state: RootState) => {
    return state.leaves.rowsPerPage;
  });

  return (
    <div className="pagination">
      <div className="pagination_section">
        <Typography type="small_regular" text="Results Per Page" color="gray" />
        <select
          className="results_per_page_dropdown"
          onChange={(e) => {
            dispatch(paginateLeaves(parseInt(e.target.value)));
          }}
          value={rowsPerPage}
        >
          <option value={5}> 5 </option>
          <option value={10}> 10 </option>
          <option value={15}> 15 </option>
          <option value={20} selected>
            20
          </option>
        </select>
      </div>
      <div className="pagination_section">
        {activePage === 1 ? (
          <Icon type="white" src="/icons/double_arrow.svg" disabled={true} />
        ) : (
          <Icon
            type="secondary"
            src="/icons/double_arrow.svg"
            onClick={() => dispatch(navigateLeaves(1))}
          />
        )}
        <Typography
          type="small_regular"
          color="gray"
          text={`Page ${activePage} of ${totalPages}`}
        />

        {activePage === 1 ? (
          <Icon type="white" src="/icons/left_arrow.svg" disabled={true} />
        ) : (
          <Icon
            type="secondary"
            src="/icons/left_arrow.svg"
            onClick={() => dispatch(navigateLeaves(activePage - 1))}
          />
        )}

        {activePage >= totalPages ? (
          <Icon type="white" src="/icons/right_arrow.svg" disabled={true} />
        ) : (
          <Icon
            type="secondary"
            src="/icons/right_arrow.svg"
            onClick={() => dispatch(navigateLeaves(activePage + 1))}
          />
        )}
      </div>
    </div>
  );
};
