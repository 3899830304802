import React from 'react';
import './_avatar.scss';

interface AvatarType extends React.ImgHTMLAttributes<HTMLImageElement> {
  size: 'xsm' | 'sm' | 'md' | 'lg' | 'xl';
  name: string;
  onClick?: any;
}

interface AvatarWithSrcType extends React.ImgHTMLAttributes<HTMLImageElement> {
  size: 'xsm' | 'sm' | 'md' | 'lg' | 'xl';
  src: string;
}

export const Avatar = (props: AvatarType) => {
  const { size, name, onClick } = props;

  const generateInitials = (name: string) => {
    if (name) {
      // Return unknown if name is empty
      if (name.length <= 0) return 'U';

      // Only if frist name supplied
      const nameArray = name.split(' ');
      if (nameArray.length <= 1) return nameArray[0][0];

      //  Multiple names supplied
      if (nameArray.length > 1) return nameArray[0][0] + nameArray[1][0];
    }
  };

  return (
    <div
      className={`avatar defaultAvatar ${size} default-theme centered`}
      onClick={onClick}
    >
      <p>{generateInitials(name)}</p>
    </div>
  );
};

export const AvatarWithSrc = (props: AvatarWithSrcType) => {
  const { src, size, alt } = props;
  return (
    <>
      <img src={src} className={`avatar ${size}`} alt={alt} />
    </>
  );
};
