import React, { useEffect } from 'react';
import { AdminHeader, EmployeeSummary, SpinnerPro } from 'components';
import { AdminSidebar } from 'components/molecules/AdminSidebar';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { getAdminEmployeeInfo } from 'store/Admin';
import { ApiResponseStatus } from 'models/enums';
import { getDesignations } from 'store/MasterData';

export const AdminDashboard = () => {
  const dispatch = useAppDispatch();

  const getEmployeeStatus = useAppSelector((state: RootState) => {
    return state.admin.getUsersStatus;
  });

  useEffect(() => {
    dispatch(getAdminEmployeeInfo());
  }, []);

  return (
    <>
      <AdminHeader />
      <div className="dashboard-wrapper">
        {getEmployeeStatus === ApiResponseStatus.pending && (
          <div className="spinner-main-wrapper">
            <SpinnerPro />
          </div>
        )}
        <AdminSidebar />
        <EmployeeSummary />
      </div>
    </>
  );
};
