import * as React from 'react';
import { useField } from 'formik';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import './_datepicker.scss';
import dayjs from 'dayjs';

interface DatepickerProp {
  labelText: string;
  helperText?: string;
  required?: boolean;
}

interface DatepickerFormikProp {
  name: string;
  labelText: string;
  disabled?: boolean;
  helperText?: string;
  required?: boolean;
  minDate?: any;
  handleChange?: (option: any) => void;
  handleDisableDate: (option: any) => boolean;
  defaultDate?: string;
}

export const Datepicker = (prop: DatepickerProp) => {
  const { labelText, helperText } = prop;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <DemoContainer components={['DatePicker']}>
        <DemoItem label={labelText}>
          <DesktopDatePicker
            defaultValue="YYYY/MM/DD"
            className="datepicker-wrapper"
            slotProps={{
              textField: {
                helperText: helperText
              }
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
};

export const DatepickerFormik = (prop: DatepickerFormikProp) => {
  const {
    name,
    labelText,
    helperText,
    required,
    disabled,
    handleChange,
    minDate,
    defaultDate,
    handleDisableDate
  } = prop;

  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const [date, setDate] = React.useState('');

  const settingDate = (val: any) => {
    setValue(val);
    setDate(val);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <DemoContainer components={['DatePicker']}>
          <DemoItem label={labelText}>
            <DesktopDatePicker
              className="datepicker-wrapper"
              slotProps={{
                textField: {
                  helperText: helperText
                }
              }}
              minDate={minDate}
              disabled={disabled}
              shouldDisableDate={(day: any) => handleDisableDate(day)}
              value={date === '' ? dayjs(defaultDate) : field.value}
              onChange={(val) => {
                handleChange?.(val);
                settingDate(val);
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>

      {!meta.touched && !meta.error && helperText && (
        <div className="input-helper-label">{helperText}</div>
      )}

      {required && meta.touched && meta.error && (
        <div className="input-error-message">{meta.error}</div>
      )}
    </>
  );
};

interface Props {
  labelText: string;
  onChange: (e: any) => void;
  defaultDate?: string;
}
export const CustomDatePicker = ({
  labelText,
  onChange,
  defaultDate
}: Props) => {
  return (
    <div>
      <label className="label"> {labelText} </label>
      <input
        type="date"
        className="html_datepicker"
        onChange={onChange}
        value={defaultDate}
      />
    </div>
  );
};
