import { UserRoles } from 'models/enums';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState, useAppSelector } from 'store';

interface ProtectedRouteProps {
  allowedRoles: UserRoles[];
}

export const ProtectedRouteAdmin = ({
  allowedRoles,
  ...props
}: ProtectedRouteProps) => {
  const userRole = useAppSelector(
    (state: RootState) => state.auth.user.authData?.data?.userRole
  );
  const token = useAppSelector((state) => state.auth?.user?.authData?.token);

  if (!token) return <Navigate to="/login" />;

  return allowedRoles.includes(userRole) ? <Outlet {...props} /> : null;
};
