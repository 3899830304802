import React from 'react';
import { useField } from 'formik';
import './_selectInput.scss';

interface SelectInputType {
  id?: string;
  label?: string;
  helperText?: string;
  customStyles?: string;
  children: React.ReactNode;
  error: boolean;
  name: string;
  required?: boolean;
  handleChange?: (option: any) => void;
  disabled?: boolean;
}

export const SelectInput = (props: SelectInputType) => {
  const { id, label, helperText, children, error, name, required } = props;

  return (
    <>
      {label ? <label className="input-label">{label}</label> : ''}
      <select
        name={name}
        id={id}
        className={`input-select-box ${error ? 'error-border' : ''}`}
        required={required}
      >
        {children}
      </select>
      {helperText ? (
        <p className={`input-helper-label ${error ? 'error-text' : ''}`}>
          {helperText}
        </p>
      ) : (
        ''
      )}
    </>
  );
};

export const SelectInputFormik = (props: SelectInputType) => {
  const {
    id,
    label,
    helperText,
    children,
    error,
    name,
    required,
    handleChange,
    disabled,
    customStyles
  } = props;

  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  return (
    <>
      {label ? <label className="input-label">{label}</label> : ''}
      <select
        id={id}
        className={`input-select-box ${
          error ? 'error-border' : ''
        } ${customStyles}`}
        required={required}
        {...field}
        onChange={handleChange}
        disabled={disabled}
      >
        {children}
      </select>

      {!meta.touched && !meta.error && helperText && (
        <div className="input-helper-label">{helperText}</div>
      )}

      {required && meta.touched && meta.error && (
        <div className="input-error-message">{meta.error}</div>
      )}
    </>
  );
};
