import React from 'react';
import { Header } from '../../molecules/Header';
import { Navigation } from '../../molecules/Navigation';
import { Divider } from '../../atoms/Divider';
import { Section1 } from '../../molecules/Section1';
import './index.scss';

interface Prop {
  portalTitle?: string;
  child1?: React.ReactNode;
  child2?: React.ReactNode;
}

export const HeaderArea = ({ child1, child2, portalTitle }: Prop) => {
  return (
    <div className="headerArea">
      <Header portalTitle={portalTitle} />
      <Divider size="lg" orientation="hr" customClass="header-divider" />
      <Navigation />
      <Section1 child1={child1} child2={child2} />
    </div>
  );
};
