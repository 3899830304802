import React from 'react';
import { HeaderSection } from '../../atoms/HeaderSection';
import { Button } from '../../atoms/Button';
import { useLocation, useNavigate } from 'react-router';

export const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let child1 = (
    <Button
      size="base"
      type="dark"
      icon="left"
      iconSrc="/icons/home.svg"
      onClick={() => navigate('/')}
    >
      HOME
    </Button>
  );
  if (location.pathname === '/') {
    child1 = <div></div>;
  }
  return <HeaderSection child1={child1} />;
};
