import React, { useEffect } from 'react';
import {
  Button,
  SelectInputFormik,
  Spinner,
  TextAreaFormik,
  Typography
} from 'components/atoms';
import { NewBreadCrumb } from 'components/molecules';
import { ViewScreen } from 'components/template';
import { Form, Formik } from 'formik';
import './index.scss';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import {
  updateLeaveRequestStatus,
  validateLeave,
  clearValidateResponse
} from 'store/Leaves';
import { toast } from 'react-toastify';
import { useQuery } from 'services/hooks';
import Chip from '@mui/material/Chip';
import moment from 'moment';

export const ApproveLeaveRequest = () => {
  const query = useQuery();
  const leaveId = query.get('leaveId');
  const updated = query.get('updated');
  const leaveValidate = useAppSelector((state: RootState) => {
    return state.leaves.validateLeave;
  });
  const status = useAppSelector((state: RootState) => {
    return state.leaves.status ? state.leaves.status : '';
  });

  const updateLeaveStatusState = useAppSelector((state: RootState) => {
    return state.leaves.updateLeaveStatusState
      ? state.leaves.updateLeaveStatusState
      : '';
  });

  const formData = new FormData();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const requestObject = {
      leaveId,
      updated
    };
    dispatch(validateLeave(requestObject));

    return () => {
      dispatch(clearValidateResponse());
    };
  }, []);

  useEffect(() => {
    if (leaveValidate?.isLatest === false) {
      toast.error(leaveValidate?.comment);
      // navigate('/LMS');
    }
  }, [leaveValidate]);

  const headerItem1 = (
    <>
      <Typography type="heading5" text="Aventude Pvt Ltd" />
      <NewBreadCrumb />
    </>
  );

  // initial values for form fields
  const formInitialValues = { dropdown: 'Approved', adminReason: '' };

  // validation for form
  const validateFields = (values: any) => {
    const errors = {} as any;
    if (values.dropdown === 'Rejected' && values.adminReason === '')
      errors.adminReason = 'Please specify reason for rejecting leave request';
  };

  // request object

  // method for submitting the form
  const modalOnSubmit = async (values: any) => {
    formData.append('leaveId', leaveId || '');
    formData.append('status', values.dropdown || '');
    formData.append('adminReason', values.adminReason || '');

    await dispatch(updateLeaveRequestStatus(formData));
  };

  const openExistingFile = (url: string) => {
    window.open(url, '_blank');
  };

  // form for approving / rejecting a leave request
  const mainItem2 = (
    <div>
      <Formik
        initialValues={formInitialValues}
        validate={validateFields}
        validateOnChange
        onSubmit={(values) => {
          modalOnSubmit(values);
        }}
      >
        {({ values, setFieldValue }: any) => (
          <div className="aprrove-screen">
            <div className="leave-details">
              <Typography
                type="small_regular"
                text={`Employee Name: ${leaveValidate?.user}`}
              />
              <Typography
                type="small_regular"
                text={`Start Date: ${moment(leaveValidate?.startDate).format(
                  'MMMM DD, YYYY'
                )}`}
              />
              <Typography
                type="small_regular"
                text={`End Date: ${moment(leaveValidate?.endDate).format(
                  'MMMM DD, YYYY'
                )}`}
              />
              <Typography
                type="small_regular"
                text={`Leave Type: ${leaveValidate?.leaveType}`}
              />
              <Typography
                type="small_regular"
                text={`Leave Session: ${leaveValidate?.session}`}
              />
              <Typography
                type="small_regular"
                text={`Leave Reason: ${leaveValidate?.reason}`}
              />
              <Typography
                type="small_regular"
                text={`Applied No of Days: ${leaveValidate?.noOfDays}`}
              />

              {leaveValidate?.leaveType !== 'Medical Leave' && (
                <Typography
                  type="small_regular"
                  text={`Available Leave Count: ${leaveValidate?.availableLeaveCount}`}
                />
              )}
              {leaveValidate?.leaveType === 'Medical Leave' && (
                <Typography
                  type="small_regular"
                  text={`Used Medical Leaves: ${leaveValidate?.noOfMedicalTaken}`}
                />
              )}
              {leaveValidate?.attachments?.length > 0 && (
                <>
                  <Typography
                    type="small_regular"
                    text={`Attachments: ${leaveValidate?.attachments?.length}`}
                  />
                  {leaveValidate?.attachments.map((attachment: any) => (
                    <div className="chip_color leave-request-chip">
                      <Chip
                        label={attachment.name}
                        onClick={() =>
                          openExistingFile(
                            process.env.REACT_APP_BLOB_STORAGE_URL +
                              attachment.name
                          )
                        }
                        clickable
                        variant="outlined"
                      />
                    </div>
                  ))}
                </>
              )}
            </div>

            <Form>
              <div className="form-wrapper">
                <SelectInputFormik
                  name="dropdown"
                  label="Approve / Reject"
                  customStyles="custom-dropdown"
                  error={false}
                  required={true}
                  handleChange={(event: any) => {
                    setFieldValue('dropdown', event.target.value);
                  }}
                >
                  <option value="Approved"> Approve </option>
                  <option value="Rejected">Reject</option>
                </SelectInputFormik>
              </div>
              <TextAreaFormik
                id="adminReason"
                name="adminReason"
                rows={5}
                placeholder={
                  values.dropdown === 'Rejected'
                    ? 'Enter reason for rejection'
                    : 'Enter reason for approval(Optional)'
                }
                required={values.dropdown === 'Rejected'}
                handleChange={(event: any) => {
                  setFieldValue('adminReason', event.target.value);
                }}
              />
              <Button
                type="success"
                htmlType="submit"
                size="lg"
                disabled={
                  leaveValidate?.isLatest === false ||
                  updateLeaveStatusState === 'fulfilled' ||
                  (values.dropdown === 'Rejected' && values.adminReason === '')
                }
              >
                SUBMIT
              </Button>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );

  return (
    <>
      {status === 'pending' && (
        <Spinner showSpinner loadingMessage={'Loading, Please Wait...'} />
      )}
      {status === 'fulfilled' && (
        <ViewScreen
          portalTitle="Leave"
          headerItem1={headerItem1}
          mainItem2={mainItem2}
        />
      )}
    </>
  );
};
