import React from 'react';
import { useNavigate } from 'react-router';
import { Typography } from 'components/atoms';
import { NewBreadCrumb } from 'components/molecules';
import { DashboardTile } from 'components/organisms';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import DescriptionIcon from '@mui/icons-material/Description';
import { AdminPanelSettings } from '@mui/icons-material';
import { ViewScreen } from 'components/template';
import { useAppSelector } from 'store';
import { UserRoles } from 'models/enums';

export const Dashboard = () => {
  const userRole = useAppSelector(
    (state) => state.auth.user.authData?.data?.userRole
  );
  const navigate = useNavigate();

  const handleRedirection = (id: string) => {
    switch (id) {
      case 'leaves':
        navigate('/LMS');
        break;

      case 'admin':
        navigate('/admin/employee-summary-dashboard');
        break;

      case 'documents':
        window.open(process.env.REACT_APP_FILE_STORAGE, '_blank');
        break;

      default:
        break;
    }
  };

  const headerItem1 = (
    <>
      <Typography type="heading5" text="Aventude Pvt Ltd" />
      <NewBreadCrumb />
    </>
  );
  const mainItem1 = (
    <DashboardTile
      title="Leave"
      type="leaves"
      subtitle="Dashboard"
      icon={<GroupRemoveIcon fontSize="large" />}
      handleNavigation={handleRedirection}
    />
  );
  const mainItem2 = (
    <DashboardTile
      title="HR Guidelines"
      type="documents"
      subtitle="Documents"
      icon={<DescriptionIcon fontSize="large" />}
      handleNavigation={handleRedirection}
    />
  );
  const mainItem3 = (
    <DashboardTile
      title="Admin"
      type="admin"
      subtitle="Portal"
      icon={<AdminPanelSettings fontSize="large" />}
      handleNavigation={handleRedirection}
    />
  );
  return (
    <ViewScreen
      mode="dashboard"
      portalTitle="Employee Portal"
      headerItem1={headerItem1}
      mainItem1={mainItem1}
      mainItem2={mainItem2}
      mainItem3={
        userRole === UserRoles.ADMIN ||
        userRole === UserRoles.MANAGER ||
        userRole === UserRoles.TEAM_LEAD ? (
          mainItem3
        ) : (
          <div />
        )
      }
    />
  );
};
