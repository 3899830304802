import axios, { AxiosRequestConfig, AxiosResponse, AxiosHeaders } from 'axios';
import { store } from 'store';
import { clearAuthState } from 'store/Auth';

export const apiInstance = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`
});

export const apiBaseInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`
});

export const apiAADInstance = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`
});

apiInstance.interceptors.request.use(
  (config) => {
    const { getState } = store;
    const { auth } = getState();
    const { user } = auth;
    const { authData } = user;

    if (authData.token && authData.token !== '') {
      config.headers.Authorization = `Bearer ${authData.token}`;
    }
    return config;
  },
  () => {
    // TODO: Error alert
  }
);

const login = async () => {
  window.location.href = `${process.env.REACT_APP_BASE_URL}/login`;
};
const logout = async () => {
  window.location.href = `${process.env.REACT_APP_BASE_URL}/logout`;
};

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const { response } = error;

    if (response.status === 401) {
      logout();
    } else {
      return Promise.reject(error);
    }
  }
);

apiAADInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const { response } = error;

    if (response.status === 401) {
      logout();
    } else {
      return Promise.reject(error);
    }
  }
);

apiBaseInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const { response } = error;

    if (response.status === 401) {
      logout();
    } else {
      return Promise.reject(error);
    }
  }
);

const responseData = <T>(response: AxiosResponse<T>) => response;

export const request = {
  aadApiGet: <T>(url: string, config?: AxiosRequestConfig) =>
    apiAADInstance.get<T>(url, config).then(responseData),
  baseGet: <T>(url: string, config?: AxiosRequestConfig) =>
    apiBaseInstance.get<T>(url, config).then(responseData),
  basePost: <T>(url: string, body: {}, config?: AxiosRequestConfig) =>
    apiBaseInstance.post<T>(url, body, config).then(responseData),
  get: <T>(url: string, config?: AxiosRequestConfig) =>
    apiInstance.get<T>(url, config).then(responseData),
  post: <T>(url: string, body: {}, config?: AxiosRequestConfig) =>
    apiInstance.post<T>(url, body, config).then(responseData),
  put: <T>(url: string, body: {}, config?: AxiosRequestConfig) =>
    apiInstance.put<T>(url, body, config).then(responseData),
  delete: <T>(url: string, config?: AxiosRequestConfig) =>
    apiInstance.delete<T>(url, config).then(responseData)
};
